import styled from 'styled-components';

export const ContainerPagination = styled.div`
  display: flex !important;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;

  span {
    font-weight: 600;
    font-size: 20px;
    margin: 0px 5px;
  }
`;

export const Image = styled.img`
  cursor: pointer;
`;
