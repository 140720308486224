import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const FormContent = styled.div`
  border-radius: 6px;
  background: #fff;
  width: 90%;
  max-width: 398px;
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormFooter = styled.div`
  font-family: Lato;
  background-color: #fff;
  border-top: 1px solid #fff;
  margin-top: -15px;
  padding: 15px;
  text-align: center;
  padding-top: 0px;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
`;

export const InputComponent = styled.input`
  & {
    background-color: #fff;
    color: #0d0d0d;
    padding: 13px 42px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    border: 1px solid #ccc;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    text-align: left;
  }

  :focus {
    background-color: #fff;
    border-bottom: 2px solid  rgba(21,129,117,255);
  }
  ::placeholder {
    color: #cccccc;
  }
`


export const H2 = styled.h2 `
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color: #cccccc;
  :inactive {
    color: #cccccc;
  };
  :active {
    color: #0d0d0d;
    border-bottom: 2px solid #5fbae9;
  }
`
export const A = styled.a `
  color: rgba(21,129,117,255);
  display:inline-block;
      text-decoration: none !important;
  font-weight: 400;
`

export const wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
`

export const BOTAO = styled.input.attrs({
  type: 'button',
  type: 'submit'
})`
  background-color: #158175;
  border: none;
  color: white;
  padding: 15px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  position:relative;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 0px 40px 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  border-bottom: 1% solid #106D63;
  :hover {
    background-color: #106D63;
  };
  :active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  };
`

export const LABEL = styled.label`
  font-family: Lato;
  color: #000;
  margin: 0;
  width: 85%;
  text-align: start;
`;

export const SPAN = styled.span`
  display: flex;
  /* justify-content: center; */
	& {
    /* position: absolute; */
  }
  &:after {
    content: "\f0e0";
    font-family: FontAwesome;
    color: #aaa;
    /* margin-left: 11%; */
    /* bottom: 0; */
    /* margin-right: -40px; */
    font-size: 1.1em;
    background: '#ccc';
  }
`

export const SPAN2 = styled.span `
  display: flex;
  /* justify-content: center; */
	& {
    /* position: absolute; */
  }
  &:after {
    content: "\f023";
    font-family: FontAwesome;
    color: #aaa;
    /* margin-left: 11%; */
    /* bottom: 0; */
    /* margin-right: -40px; */
    font-size: 1.3em;
    background: '#ccc';
  }
`

export const I = styled.i`
	& {
    position: relative;
	  font-style: inherit;
  }
  &:after {
      font-family: Lato;
      float: left;
      margin-left: -1.5em;
      content: "\f023";
      color: #aaa;
      left: 15px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 1.7em;
      top: -8px;
  }
  &:after {
    content: "\f023" !important;
  }
`

export const H1 = styled.h1`
  font-family: Lato;
  letter-spacing: 2.5px;
  font-size: 30px;
  color:rgba(21,129,117,255);
  margin-top: 15px;
`

export const Info = styled.p`
  font-family: Lato;
  font-size: 15px;
  color: #aaa;
`;