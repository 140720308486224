import React from 'react';
import { format, isSameDay, isSameMonth, isBefore, isAfter, addMonths, subMonths } from 'date-fns'
import { takeMonth } from '../../modules/calendar';
import {
    SingleContainer,
    MonthContainer,
    DatesContainer,
    DateCell,
    MonthRow,
    ChangeMonthButton,
} from './style';

function WeekNames() {
    return <tr>
        {
            ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'].map(
                (dayName) => (
                    <td>
                        {dayName}
                    </td>
                ),
            )
        }
    </tr>
}


export default function Calendar({firstDate, setFirstDate, lastDate, setLastDate}) {

    const monthDic = {
        January: 'Janeiro',
        February: 'Fevereiro',
        March: 'Março',
        April: 'Abril',
        May: 'Maio',
        June: 'Junho',
        July: 'Julho',
        August: 'Agosto',
        September: 'Setembro',
        October: 'Outubro',
        November: 'Novembro',
        December: 'Dezembro',
    };

    function MonthlyCalendar1() {
        const data = takeMonth(firstDate)();

        function dayStyle(day) {
            //selected date
            if (isSameDay(day, firstDate) || isSameDay(day, lastDate)) return { color: 'white', background: '#007469', cursor: 'pointer' };

            //in range
            if (isBefore(day, lastDate) && isAfter(day, firstDate)) return { background: '#00746950', cursor: 'pointer' };

            return { cursor: 'pointer' };
        }

        return (
            <SingleContainer>
                <h1 style={{ fontSize: '.7rem', fontWeight: '700', color: '#007469' }}>{format(firstDate, 'yyyy')}</h1>

                <MonthRow>
                    <ChangeMonthButton
                        onClick={() => setFirstDate(subMonths(firstDate, 1))}
                    >
                        {"<"}
                    </ChangeMonthButton>
                    <h1 style={{ fontSize: '1rem', fontWeight: '700' }}>{monthDic[format(firstDate, 'MMMM')]}</h1>
                    <ChangeMonthButton
                        onClick={() => setFirstDate(addMonths(firstDate, 1))}
                    >
                        {">"}
                    </ChangeMonthButton>
                </MonthRow>
                <DatesContainer cellSpacing={0}>
                    <WeekNames />
                    {data.map((week) => (
                        <tr>
                            {week.map(
                                (day) => {
                                    return (
                                        <DateCell
                                            onClick={() => {
                                                if (isSameMonth(day, firstDate) && (isBefore(day, lastDate) || isSameDay(day, lastDate))) {
                                                    setFirstDate(day)
                                                }
                                            }}
                                            style={dayStyle(day)}
                                        >
                                            {
                                                isSameMonth(day, firstDate) ? (format(day, 'dd')) : (" ")
                                            }
                                        </DateCell>
                                    )
                                }

                            )
                            }
                        </tr>
                    )
                    )
                    }
                </DatesContainer>
            </SingleContainer>
        );
    }

    function MonthlyCalendar2() {
        const data = takeMonth(lastDate)();

        function dayStyle(day) {
            //selected date
            if (isSameDay(day, firstDate) || isSameDay(day, lastDate)) return { color: 'white', background: '#007469', cursor: 'pointer' };

            //in range
            if (isBefore(day, lastDate) && isAfter(day, firstDate)) return { background: '#00746950', cursor: 'pointer' };

            return { cursor: 'pointer' };
        }

        return (
            <SingleContainer>
                <h1 style={{ fontSize: '.7rem', fontWeight: '700', color: '#007469' }}>{format(lastDate, 'yyyy')}</h1>

                <MonthRow>
                    <ChangeMonthButton
                        onClick={() => setLastDate(subMonths(lastDate, 1))}
                    >
                        {"<"}
                    </ChangeMonthButton>
                    <h1 style={{ fontSize: '1rem', fontWeight: '700' }}>{monthDic[format(lastDate, 'MMMM')]}</h1>
                    <ChangeMonthButton
                        onClick={() => setLastDate(addMonths(lastDate, 1))}
                    >
                        {">"}
                    </ChangeMonthButton>
                </MonthRow>
                <DatesContainer cellSpacing={0}>
                    <WeekNames />
                    {data.map((week) => (
                        <tr>
                            {week.map(
                                (day) => {
                                    return (
                                        <DateCell
                                            onClick={() => {
                                                if (isSameMonth(day, lastDate) && (isAfter(day, firstDate) || isSameDay(day, firstDate))) {
                                                    setLastDate(day)
                                                }
                                            }}
                                            style={dayStyle(day)}
                                        >
                                            {
                                                isSameMonth(day, lastDate) ? (format(day, 'dd')) : (" ")
                                            }
                                        </DateCell>
                                    )
                                }

                            )
                            }
                        </tr>
                    )
                    )
                    }
                </DatesContainer>
            </SingleContainer>
        );
    }

    return (
        <MonthContainer>
            <MonthlyCalendar1 />
            <MonthlyCalendar2 />
        </MonthContainer>
    );
}