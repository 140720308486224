import React from 'react';
import Spinner from 'react-bootstrap/Spinner'
import { Container } from './style';

const LoadingState = () => {
    return (
        <Container>
            <Spinner animation="border" />
        </Container>
    )
}

export default LoadingState;