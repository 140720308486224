import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import {
  Form,
  Button,
  Col,
  Table,
  ButtonGroup,
  Pagination,
  Card,
  Tab,
  Tabs,
  InputGroup,
  Modal,
  ButtonToolbar,
  FormLabel,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';

import { format } from 'date-fns';
import { ptBR, ro } from 'date-fns/locale';

import SignatureCanvas from 'react-signature-canvas';

import './tokbox.css';

import api from '../../services/api';

export function FormAnamnese(props) {
  async function submit(values) {
    try {
      const { data } = await api.patch(
        `/consultations/${props.consultation.id}/`,
        {
          ...values,
        }
      );
      delete data['patient'];
      toast.success('Formulário salvo com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
      });
      props.setConsultation({ ...props.consultation, ...data });
    } catch (error) {
      console.log(error);
    }
  }

  const schema = Yup.object({
    main_complaint: Yup.string().required(),
  });

  return (
    <Formik
      onSubmit={submit}
      validationSchema={schema}
      initialValues={{
        main_complaint: props.consultation.main_complaint,
        current_history_disease: props.consultation.current_history_disease,
        diagnostic_hypothesis: props.consultation.description,
        conduct: props.consultation.conduct,
        cid: props.consultation.cid,
        description: props.consultation.description,
        observation: props.consultation.observation,
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div className='umpartedois'>
          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Queixa Principal</Form.Label>
            <Form.Control
              as='textarea'
              rows='3'
              name='main_complaint'
              value={values.main_complaint}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={!!errors.main_complaint}
            />
          </Form.Group>

          <Form.Group controlId='formBasicEmail'>
            <Form.Label>História da Doença Atual</Form.Label>
            <Form.Control
              as='textarea'
              rows='3'
              name='current_history_disease'
              value={values.current_history_disease}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Hipótese Diagnóstica</Form.Label>
            <Form.Control
              as='textarea'
              rows='3'
              name='diagnostic_hypothesis'
              value={values.diagnostic_hypothesis}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Conduta</Form.Label>
            <Form.Control
              as='textarea'
              rows='3'
              name='conduct'
              value={values.conduct}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col} md='3' controlId='formBasicEmail'>
              <Form.Label>CID</Form.Label>
              <Form.Control
                name='cid'
                value={values.cid}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} md='9' controlId='formBasicEmail'>
              <Form.Label>Descrição do Código</Form.Label>
              <Form.Control
                name='observation'
                value={values.observation}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Form.Group>
          </Form.Row>

          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              as='textarea'
              rows='3'
              name='description'
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>
          <div className='rodape'>
            <Button variant='success' type='submit' onClick={handleSubmit}>
              Salvar
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
}

export function FormDocs(props) {
  const [fileUpload, setFileUpload] = useState(null);
  const [fileName, setFileName] = useState('');
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    api
      .get(`/documents/`)
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((err) => {
        console.log('form documentos', err);
      });
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();

    if (fileName.length > 0 && fileUpload !== null) {
      let formData = new FormData();
      formData.append('consultation', props.consultation.id);
      formData.append('name_document', fileName);
      formData.append('attachment', fileUpload);
      try {
        const { data } = await api.post(`/documents/`, formData);
        toast.success('Arquivo salvo com sucesso', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDocuments((prevState) => [...prevState, data]);
      } catch (error) {
        console.log(error.response);
      }
    } else {
      toast.error('Falha ao enviar o formulário', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <div className='umpartedois'>
      <form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Group>
            <Form.Label>Nome do documento</Form.Label>
            <Form.Control
              id='name'
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group>
          <Form.Group>
            <Form.Label>Arquivo</Form.Label>
            <Form.Control
              id='fileUpload'
              type='file'
              onChange={(e) => setFileUpload(e.target.files[0])}
            />
          </Form.Group>
        </Form.Group>
        <div className='btss'>
          <Button variant='success' type='submit'>
            Salvar
          </Button>
        </div>
      </form>

      <div className='seteparteum'>
        <h5>Histórico de arquivos</h5>
      </div>
      <div className='tableResponsive'>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th className='ticelula'>Nome</th>
              <th className='ticelula'>Acessar</th>
            </tr>
          </thead>
          <tbody>
            {documents &&
              documents.length > 0 &&
              documents.map((row, index) => (
                <tr key={index}>
                  <td className='text-center'>{row.name_document}</td>
                  <td className='text-center'>
                    <i
                      className='fa fa-file'
                      style={{
                        cursor: 'pointer',
                        color: '#007469',
                        fontSize: 20,
                      }}
                      onClick={() => window.open(row.attachment, '_blank')}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function FormAlert(props) {
  async function submit(values) {
    try {
      const { data } = await api.patch(
        `/consultations/${props.consultation.id}/`,
        {
          alert: values.dscAlert,
          alerts: values.tpAlert,
        }
      );
      delete data['patient'];
      toast.success('Formulário salvo com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
      });
      props.setConsultation({ ...props.state, ...data });
    } catch (error) {
      console.log(error.response);
    }
  }

  // const schema = Yup.object({
  //   tpAlert: Yup.string().required(),
  //   dscAlert: Yup.string().required(),
  // });

  return (
    <Formik
      onSubmit={submit}
      // validationSchema={schema}
      initialValues={{
        tpAlert: props.consultation.alerts,
        dscAlert: props.consultation.alert,
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <>
          <div className='umpartedois'>
            <div>
              <Form.Group as={Col} md='12' controlId='formBasicEmail'>
                <Form.Label>Tipo de alerta:</Form.Label>
                <Form.Control
                  as='select'
                  name='tpAlert'
                  value={values.tpAlert}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value='1'>Evacuação</option>
                  <option value='2'>Diarréia</option>
                  <option value='3'>Constipação a mais de 48 horas</option>
                  <option value='4'>Sangramento digestivo alto</option>
                  <option value='5'>Sem anormalidades</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='formBasicEmail'>
                <Form.Label>Descrição do alerta:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows='3'
                  name='dscAlert'
                  value={values.dscAlert}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            <div className='rodape'>
              <Button variant='success' type='submit' onClick={handleSubmit}>
                Salvar
              </Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

// Prototipação da Tela de CheckList
export function FormCheckList(props) {
  async function submit(values) {
    try {
      const { data } = await api.patch(
        `/consultations/${props.consultation.id}/`,
        {
          ...values,
        }
      );
      toast.success('Formulário salvo com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
      });
      delete data['patient'];
      props.setConsultation({ ...props.consultation, ...data });
    } catch (error) {
      console.log(error);
    }

    values = {
      ...values,
      system_nervoso: values.system_nervoso.indexOf(true),
      system_respiratorio: values.system_respiratorio.indexOf(true),
      system_cardiovascular: values.system_cardiovascular.indexOf(true),
      system_gastrointestinal: values.system_gastrointestinal.indexOf(true),
    };
    console.log(values);
  }

  const [doctor, setDoctor] = useState([]);
  const [especialidade, setEspecialidade] = useState('');
  const [sistemaNervoso, setSistemaNervoso] = useState('');
  const [sistemaRespiratorio, setSistemaRespiratorio] = useState('');
  const [sistemaCardio, setSistemaCardio] = useState('');
  const [sistemaGastro, setSistemaGastro] = useState('');
  const [imc, setImc] = useState(0);
  const [peso, setPeso] = useState(0);
  const [altura, setAltura] = useState(0);

  useEffect(() => {
    setImc(props.consultation.imc);
    setPeso(props.consultation.weight);
    setAltura(props.consultation.height);
  }, [props.consultation.height, props.consultation.imc, props.consultation.weight]);

  useEffect(() => {
    if (!peso || !altura) setImc(0);
    else {
      const newImc = peso / (altura * altura);
      setImc(newImc.toFixed(2));
    }
  }, [peso, altura]);

  useEffect(() => {
    async function getDoctor() {
      api.get(`/doctors/${props.consultation.doctor}/`).then((resp) => {
        setDoctor(resp.data.name);
        setEspecialidade(resp.data.specialty[0].name);
      });
    }

    var tempNervoso = [];
    tempNervoso[props.consultation.system_nervoso] = true;
    setSistemaNervoso(tempNervoso);

    var tempRespiratorio = [];
    tempRespiratorio[props.consultation.system_respiratorio] = true;
    setSistemaRespiratorio(tempRespiratorio);

    var tempCardio = [];
    tempCardio[props.consultation.system_cardiovascular] = true;
    setSistemaCardio(tempCardio);

    var tempGastro = [];
    tempGastro[props.consultation.system_gastrointestinal] = true;
    setSistemaGastro(tempGastro);

    getDoctor();
  }, [
    props.consultation.doctor,
    props.consultation.system_cardiovascular,
    props.consultation.system_nervoso,
    props.consultation.system_respiratorio,
    props.consultation.system_gastrointestinal,
  ]);

  function handleNerv(id) {
    var arr = [];
    arr[sistemaNervoso.indexOf(true)] = false;
    arr[id] = true;
    setSistemaNervoso(arr);
  }

  function handleResp(id) {
    var arr = [];
    arr[sistemaRespiratorio.indexOf(true)] = false;
    arr[id] = true;
    setSistemaRespiratorio(arr);
  }

  function handleCardio(id) {
    var arr = [];
    arr[sistemaCardio.indexOf(true)] = false;
    arr[id] = true;
    setSistemaCardio(arr);
  }

  function handleGastro(id) {
    var arr = [];
    arr[sistemaGastro.indexOf(true)] = false;
    arr[id] = true;
    setSistemaGastro(arr);
  }

  return (
    <Formik
      onSubmit={submit}
      initialValues={{
        time: '00:' + props.consultation.consultation_duration_type,
        profissional: doctor,
        specialty: especialidade,
        result: props.consultation.result,
        listProblens: props.consultation.list_de_problems,
        observations: props.consultation.observation,
        system_nervoso: sistemaNervoso,
        system_respiratorio: sistemaRespiratorio,
        system_cardiovascular: sistemaCardio,
        system_gastrointestinal: sistemaGastro,
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <>
          {/* <div className="umparteum">
              <div> */}
          {/* <Form.Group controlId="formBasicEmail">
                  <Form.Label>Data</Form.Label>
                  <Form.Control
                    type="date"
                    name='date'
                    value={values.date}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted">
                    {errors.date}
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Hora</Form.Label>
                  <Form.Control
                    type="time"
                    name='time'
                    value={values.time}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted">
                    {errors.time}
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Profissional</Form.Label>
                  <Form.Control
                    name='profissional'
                    value={values.profissional}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted">
                    {errors.profissional}
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Especialidade</Form.Label>
                  <Form.Control
                    name='specialty'
                    value={values.specialty}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted">
                    {errors.specialty}
                  </Form.Text>
                </Form.Group> */}

          {/* </div>
            </div> */}
          <div className='umpartedoiss'>
            <div>
              <p>Informações:</p>
              <div className='boxCheckbox2'>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Peso:</Form.Label>
                  <Form.Control
                    name='weight'
                    value={peso}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setPeso(e.target.value);
                    }}
                  />
                  <Form.Text className='text-muted'>{errors.weight}</Form.Text>
                </Form.Group>

                <div style={{ width: '2%' }} />

                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Altura:</Form.Label>
                  <Form.Control
                    name='height'
                    value={altura}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setAltura(e.target.value);
                    }}
                  />
                  <Form.Text className='text-muted'>{errors.height}</Form.Text>
                </Form.Group>

                <div style={{ width: '2%' }} />

                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>IMC:</Form.Label>
                  <Form.Control name='imc' value={imc} disabled />
                  <Form.Text className='text-muted'>{errors.imc}</Form.Text>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className='umpartedois'>
            <Form.Group as={Col} md='12' controlId='formBasicEmail'>
              <Form.Label>Sistema Nervoso:</Form.Label>
              <Form.Control
                as='select'
                name='system_nervoso'
                value={values.system_nervoso}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value='1'>Selecione</option>
                <option value='2'>Rebaixamento do nível de consciência</option>
                <option value='3'>Agitação</option>
                <option value='4'>Sonolência</option>
                <option value='5'>Sem anomalidades</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className='umpartedois'>
            <Form.Group as={Col} md='12' controlId='formBasicEmail'>
              <Form.Label>Sistema Respiratório:</Form.Label>
              <Form.Control
                as='select'
                name='system_respiratorio'
                value={values.system_respiratorio}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value='1' selected>
                  Selecione
                </option>
                <option value='2'>Desconforto Respiratório</option>
                <option value='3'>{`FR > 22ipm`}</option>
                <option value='4'>Tosse Profunda</option>
                <option value='5'>Sangramento Respiratório</option>
                <option value='6'>Cianose</option>
                <option value='7'>Sem anomalidades</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className='umpartedois'>
            <Form.Group as={Col} md='12' controlId='formBasicEmail'>
              <Form.Label>Sistema Cardiovascular:</Form.Label>
              <Form.Control
                as='select'
                name='system_cardiovascular'
                value={values.system_cardiovascular}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value='1'>Selecione</option>
                <option value='2'>Rebaixamento do nível de consciência</option>
                <option value='3'>Agitação</option>
                <option value='4'>Sonolência</option>
                <option value='5'>Sem anomalidades</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className='umpartedois'>
            <Form.Group as={Col} md='12' controlId='formBasicEmail'>
              <Form.Label>Sistema Gastrointestinal:</Form.Label>
              <Form.Control
                as='select'
                name='system_gastrointestinal'
                value={values.system_gastrointestinal}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value='1'>Selecione</option>
                <option value='2'>Rebaixamento do nível de consciência</option>
                <option value='3'>Agitação</option>
                <option value='4'>Sonolência</option>
                <option value='5'>Sem anomalidades</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className='checkListObs'>
            <Form.Group controlId='formBasicEmail'>
              <Form.Label>Lista de problemas: </Form.Label>
              <Form.Control
                as='textarea'
                rows='4'
                name='list_de_problems'
                value={values.list_de_problems}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Form.Text className='text-muted'>
                {errors.list_de_problems}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId='formBasicEmail'>
              <Form.Label>Observações: </Form.Label>
              <Form.Control
                as='textarea'
                rows='4'
                name='observation'
                value={values.observation}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Form.Text className='text-muted'>{errors.observation}</Form.Text>
            </Form.Group>
          </div>
          <div className='rodape'>
            <Button variant='success' type='submit' onClick={handleSubmit}>
              Salvar
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
}

export function FormPrescricao(props) {
  const [medications, setMedications] = useState([]);
  const [dataMedications, setDataMedications] = useState([]);
  const [showMedications, setShowMedications] = useState(false);

  const [diluents, setDiluents] = useState([]);
  const [dataDiluents, setDataDiluents] = useState([]);
  const [showDiluents, setShowDiluents] = useState(false);

  const [dataExams, setDataExams] = useState([]);
  const [showDataExams, setShowDataExame] = useState(false);

  const [interval] = useState('');
  const [days_to_use] = useState('');
  const [start_date, setStart_date] = useState('');
  const [start_time, setStart_time] = useState('');
  const [end_date, setEnd_date] = useState('');
  const [justification] = useState('');
  const [observation] = useState('');

  const [showDataDiet, setShowDataDiet] = useState(false);
  const [dataDiet, setDataDiet] = useState([]);

  const [medicines, setMedicines] = useState(null);
  const [nutritions, setNutritions] = useState(null);
  const [exams, setExams] = useState(null);

  useEffect(() => {
    api
      .get(`/registered_medications/`)
      .then((response) => {
        setMedications(response.data);
        setDataMedications(response.data);
      })
      .catch((err) => {
        console.log('form prescricao', err);
      });

    api
      .get(`/diluents/`)
      .then((response) => {
        setDiluents(response.data.results);
        setDataDiluents(response.data.results);
      })
      .catch((err) => {
        console.log('form diluents', err);
      });

    getMedicines(props.consultation.id);
    getNutritions(props.consultation.id);
    getExamsData(props.consultation.id);
  }, [props.consultation.id]);

  const getMedicines = (id) => {
    api.get('/medications/?consultation=' + id).then(({ data }) => {
      console.log('medicines', data);

      if (data.length) {
        setMedicines(data[0]);
        setStart_date(data[0].start_date);
        setEnd_date(data[0].end_date);
        setStart_time(data[0].start_time);
      } else {
        setMedicines({});
      }
    });
  };

  const getNutritions = (id) => {
    api.get('/nutritions/?consultation=' + id).then(({ data }) => {
      console.log('nutritions', data);
      if (data.length) setNutritions(data[0]);
      else setNutritions({});
    });
  };

  const getExamsData = (id) => {
    api.get('/exams/?consultation=' + id).then(({ data }) => {
      console.log('exams', data);
      if (data.length) setExams(data[0]);
      else setExams({});
    });
  };

  async function submitMedication(values) {
    try {
      await api.post(`/medications/`, {
        ...values,
        consultation: props.consultation.id,
        add_diluent: values.add_diluent.id,
        medication_data: values.medication_data.id,
      });
      toast.success('Formulário salvo com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const getDiet = useCallback(
    debounce((value) => {
      api
        .get(`diets/?name=${value}`)
        .then((data) => setDataDiet(data.data.results));
    }, 800),
    []
  );

  async function submitNutritions(values) {
    try {
      await api.post(`/nutritions/`, {
        ...values,
        consultation: props.consultation.id,
        diet: values.diet.id,
      });
      toast.success('Formulário salvo com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const getExams = useCallback(
    debounce((value) => {
      api
        .get(`exams_data/?name=${value}`)
        .then((data) => setDataExams(data.data.results));
    }, 800),
    []
  );

  async function submitExams(values) {
    try {
      await api.post(`/exams/`, {
        consultation: props.consultation.id,
        ...values,
        exam_requested: values.exam_requested.id,
      });
      toast.success('Formulário salvo com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const filterMedication = useCallback(
    (value) => {
      if (!dataMedications || dataMedications.length === 0) return;
      if (!showMedications) setShowMedications(true);
      const medics = dataMedications.filter((medic) =>
        medic.item.toUpperCase().includes(value.toUpperCase())
      );
      setMedications(medics);
    },
    [showMedications, dataMedications]
  );

  const filterDiluents = useCallback(
    (value) => {
      if (!dataDiluents || dataDiluents.length === 0) return;
      if (!showDiluents) setShowMedications(true);
      const dilus = dataDiluents.filter((d) =>
        d.description.toUpperCase().includes(value.toUpperCase())
      );
      setDiluents(dilus);
    },
    [showDiluents, dataDiluents]
  );

  return (
    <>
      {medicines !== null && (
        <Formik
          onSubmit={submitMedication}
          validationSchema={Yup.object().shape({
            medication_data: Yup.object().shape({
              id: Yup.string().required(),
              value: Yup.string().required(),
            }),
            medical_drive: Yup.string().required(),
            interval: Yup.string().required(),
            dose: Yup.string().required(),
            days_to_use: Yup.string().required(),
            add_diluent: Yup.object().shape({
              id: Yup.string().required(),
              value: Yup.string().required(),
            }),
            start_date: Yup.string().required(),
            start_time: Yup.string().required(),
            end_date: Yup.string().required(),
            justification: Yup.string().required(),
            observation: Yup.string().required(),
          })}
          initialValues={{
            medication_data: {
              id:
                medicines && medicines.medication_data
                  ? medicines.medication_data.id
                  : '',
              value:
                medicines && medicines.medication_data
                  ? medicines.medication_data.item
                  : '',
            },
            medical_drive: medicines ? medicines.medical_drive : '',
            interval: medicines ? medicines.interval : '',
            dose: medicines ? medicines.dose : '',
            add_diluent: {
              id:
                medicines && medicines.add_diluent
                  ? medicines.add_diluent.id
                  : '',
              value:
                medicines && medicines.add_diluent
                  ? medicines.add_diluent.description
                  : '',
            },
            days_to_use: medicines ? medicines.days_to_use : '',
            start_date: medicines ? medicines.start_date : '',
            start_time: medicines ? medicines.start_time : '',
            end_date: medicines ? medicines.end_date : '',
            justification: medicines ? medicines.justification : '',
            observation: medicines ? medicines.observation : '',
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <div className='umpartedois'>
                <h6>Soluções e Medicamentos:</h6>
              </div>

              <div className='separationForms'>
                <div className='rowWbb' style={{ width: '100%' }}>
                  <div style={{ position: 'relative', width: '100%' }}>
                    <Form.Group as={Col} controlId='formBasicEmail'>
                      <Form.Label>Item:</Form.Label>
                      <Form.Control
                        name='medication_data'
                        onFocus={() => setShowMedications(true)}
                        onBlur={debounce(() => setShowMedications(false), 500)}
                        value={values.medication_data.value}
                        onChange={(value) => {
                          setFieldValue('medication_data', {
                            id: '',
                            value: value.target.value,
                          });
                          filterMedication(value.target.value);
                        }}
                        isInvalid={!!errors.medication_data}
                      ></Form.Control>
                    </Form.Group>
                    {showMedications && (
                      <div className='absoluteList'>
                        {medications.map((medic) => (
                          <Button
                            key={medic.id}
                            variant='light'
                            size='sm'
                            style={{ width: '100%', textAlign: 'left' }}
                            onClick={() => {
                              setFieldValue('medication_data', {
                                id: medic.id,
                                value: medic.item,
                              });
                              setShowMedications(false);
                            }}
                          >
                            {medic.item}
                          </Button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className='rowWbb'>
                  <Form.Group as={Col} md='4' controlId='formBasicEmail'>
                    <Form.Label>Unidade de Medida:</Form.Label>
                    <Form.Control
                      name='medical_drive'
                      value={values.medical_drive}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={!!errors.medical_drive}
                    />
                  </Form.Group>
                </div>

                <div className='rowWbb'>
                  <Form.Group as={Col} md='4' controlId='formBasicEmail'>
                    <Form.Label>Intervalo:</Form.Label>
                    <Form.Control
                      as='select'
                      name='interval'
                      value={values.interval}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={!!errors.interval}
                    >
                      <option value='' selected>
                        --------
                      </option>
                      <option value='1x ao dia'>1x ao dia</option>
                      <option value='2x ao dia'>2x ao dia</option>
                      <option value='3x ao dia'>3x ao dia</option>
                      <option value='1/1 h'>1/1 h</option>
                      <option value='2/2 h'>2/2 h</option>
                      <option value='3/3 h'>3/3 h</option>
                      <option value='4/4 h'>4/4 h</option>
                      <option value='6/6 h'>6/6 h</option>
                      <option value='8/8 h'>8/8 h</option>
                      <option value='12/12 h'>12/12 h</option>
                      <option value='48/48 h'>48/48 h</option>
                      <option value='72/72 h'>72/72 h</option>
                      <option value='1x semanal'>1x semanal</option>
                      <option value='2x semanal'>2x semanal</option>
                      <option value='3x semanal'>3x semanal</option>
                      <option value='4x semanal'>4x semanal</option>
                      <option value='5x semanal'>5x semanal</option>
                      <option value='6x semanal'>6x semanal</option>
                      <option value='7x semanal'>7x semanal</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md='4' controlId='formBasicEmail'>
                    <Form.Label>Dose</Form.Label>
                    <Form.Control
                      name='dose'
                      value={values.dose}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={!!errors.dose}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md='4' controlId='formBasicEmail'>
                    <Form.Label>Dias para uso</Form.Label>
                    <Form.Control
                      name='days_to_use'
                      value={values.days_to_use}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={!!errors.days_to_use}
                    />
                  </Form.Group>
                </div>
                <div className='rowWbb'>
                  <div style={{ position: 'relative', width: '100%' }}>
                    <Form.Group as={Col} controlId='formBasicEmail'>
                      <Form.Label>Adicionar Diluente</Form.Label>
                      <Form.Control
                        name='add_diluent'
                        value={values.add_diluent.value}
                        onFocus={() => setShowDiluents(true)}
                        onBlur={debounce(() => setShowDiluents(false), 500)}
                        onChange={(value) => {
                          setFieldValue('add_diluent', {
                            id: '',
                            value: value.target.value,
                          });
                          filterDiluents(value.target.value);
                        }}
                        isInvalid={!!errors.add_diluent}
                      ></Form.Control>
                    </Form.Group>
                    {showDiluents && (
                      <div className='absoluteList'>
                        {diluents.map((diluent) => (
                          <Button
                            key={diluent.id}
                            variant='light'
                            size='sm'
                            style={{ width: '100%', textAlign: 'left' }}
                            onClick={() => {
                              setFieldValue('add_diluent', {
                                id: diluent.id,
                                value: diluent.description,
                              });
                              setShowDiluents(false);
                            }}
                          >
                            {diluent.description}
                          </Button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className='rowWbb'>
                  <Form.Group as={Col} md='6' controlId='formBasicEmail'>
                    <Form.Label>Início</Form.Label>
                    <Form.Control
                      type='date'
                      name='start_date'
                      value={values.start_date}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={!!errors.start_date}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md='6' controlId='formBasicEmail'>
                    <Form.Label>Horário</Form.Label>
                    <Form.Control
                      type='time'
                      name='start_time'
                      value={values.start_time}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={!!errors.start_time}
                    />
                  </Form.Group>
                </div>
                <div className='rowWbb'>
                  <Form.Group as={Col} md='6' controlId='formBasicEmail'>
                    <Form.Label>Fim</Form.Label>
                    <Form.Control
                      type='date'
                      name='end_date'
                      value={values.end_date}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={!!errors.end_date}
                    />
                  </Form.Group>
                </div>

                <div className='rowWbb'>
                  <h6 style={{ color: 'green', fontWeight: 600 }}>
                    Informações Adicionais
                  </h6>
                </div>
                <div className='rowWbb'>
                  <Form.Group
                    controlId='formBasicEmail'
                    style={{ width: '100%' }}
                  >
                    <Form.Label>Justificativa</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows='3'
                      name='justification'
                      value={values.justification}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={!!errors.justification}
                    />
                  </Form.Group>
                </div>
                <div className='rowWbb'>
                  <Form.Group
                    controlId='formBasicEmail'
                    style={{ width: '100%' }}
                  >
                    <Form.Label>Observações</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows='3'
                      name='observation'
                      value={values.observation}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={!!errors.observation}
                    />
                  </Form.Group>
                </div>
                <div className='umpartedois'>
                  <div className='rodape'>
                    <Button
                      variant='success'
                      type='submit'
                      onClick={handleSubmit}
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      )}
      {nutritions !== null && (
        <Formik
          onSubmit={submitNutritions}
          validationSchema={Yup.object().shape({
            diet: Yup.object().shape({
              id: Yup.string().required(),
              value: Yup.string().required(),
            }),
            interval: Yup.string().required(),
            days_to_use: Yup.string().required(),
            start_date: Yup.string().required(),
            start_time: Yup.string().required(),
            end_date: Yup.string().required(),
            observation: Yup.string().required(),
          })}
          initialValues={{
            diet: {
              id: nutritions && nutritions.diet ? nutritions.diet.id : '',
              value: nutritions && nutritions.diet ? nutritions.diet.name : '',
            },
            interval: nutritions ? nutritions.interval : '',
            days_to_use: nutritions ? nutritions.days_to_use : '',
            start_date: nutritions ? nutritions.start_date : '',
            end_date: nutritions ? nutritions.end_date : '',
            observation: nutritions ? nutritions.observation : '',
            start_time: nutritions ? nutritions.start_time : '',
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <div className='rowWbb'>
                <h5>Nutrição</h5>
              </div>
              <div className='separationForms'>
                <Form>
                  <Form.Row>
                    <div
                      style={{
                        width: '45%',
                        marginRight: 5,
                        marginLeft: 5,
                        position: 'relative',
                      }}
                    >
                      <Form.Group controlId='formBasicEmail'>
                        <Form.Label>Dieta/jejum:</Form.Label>
                        <Form.Control
                          name='diet'
                          value={values.diet.value}
                          onFocus={() => setShowDataDiet(true)}
                          onBlur={debounce(() => setShowDataDiet(false), 500)}
                          onChange={(value) => {
                            setFieldValue('diet', {
                              id: '',
                              value: value.target.value,
                            });
                            getDiet(value.target.value);
                          }}
                          isInvalid={!!errors.diet}
                        ></Form.Control>
                      </Form.Group>
                      {showDataDiet && (
                        <div className='absoluteList'>
                          {dataDiet.map((diet) => (
                            <Button
                              key={diet.id}
                              variant='light'
                              size='sm'
                              style={{ width: '100%', textAlign: 'left' }}
                              onClick={() => {
                                setFieldValue('diet', {
                                  id: diet.id,
                                  value: diet.name,
                                });
                                setShowDataDiet(false);
                              }}
                            >
                              {diet.name}
                            </Button>
                          ))}
                        </div>
                      )}
                    </div>

                    <Form.Group as={Col} md='6' controlId=''>
                      <Form.Label>Intervalo:</Form.Label>
                      <Form.Control
                        as='select'
                        name='interval'
                        value={values.interval}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errors.interval}
                      >
                        <option value='' selected>
                          --------
                        </option>
                        <option value='1x ao dia'>1x ao dia</option>
                        <option value='2x ao dia'>2x ao dia</option>
                        <option value='3x ao dia'>3x ao dia</option>
                        <option value='1/1 h'>1/1 h</option>
                        <option value='2/2 h'>2/2 h</option>
                        <option value='3/3 h'>3/3 h</option>
                        <option value='4/4 h'>4/4 h</option>
                        <option value='6/6 h'>6/6 h</option>
                        <option value='8/8 h'>8/8 h</option>
                        <option value='12/12 h'>12/12 h</option>
                        <option value='48/48 h'>48/48 h</option>
                        <option value='72/72 h'>72/72 h</option>
                        <option value='1x semanal'>1x semanal</option>
                        <option value='2x semanal'>2x semanal</option>
                        <option value='3x semanal'>3x semanal</option>
                        <option value='4x semanal'>4x semanal</option>
                        <option value='5x semanal'>5x semanal</option>
                        <option value='6x semanal'>6x semanal</option>
                        <option value='7x semanal'>7x semanal</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md='6' controlId=''>
                      <Form.Label>Dias para uso:</Form.Label>
                      <Form.Control
                        name='days_to_use'
                        value={values.days_to_use}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errors.days_to_use}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md='6' controlId=''>
                      <Form.Label>Data Início:</Form.Label>
                      <Form.Control
                        type='date'
                        name='start_date'
                        value={values.start_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errors.start_date}
                      ></Form.Control>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md='6' controlId=''>
                      <Form.Label>Horário</Form.Label>
                      <Form.Control
                        type='time'
                        name='start_time'
                        value={values.start_time}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errors.start_time}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md='6' controlId=''>
                      <Form.Label>Data Fim:</Form.Label>
                      <Form.Control
                        type='date'
                        name='end_date'
                        value={values.end_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errors.end_date}
                      ></Form.Control>
                    </Form.Group>
                  </Form.Row>

                  <Form.Group id='formGridCheckbox'>
                    <Form.Group controlId='exampleForm.ControlTextarea1'>
                      <Form.Label>Descrição da Dieta:</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows='3'
                        name='observation'
                        value={values.observation}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errors.observation}
                      />
                    </Form.Group>
                  </Form.Group>
                </Form>
                <div className='umpartedois'>
                  <div className='rodape'>
                    <Button
                      variant='success'
                      type='submit'
                      onClick={handleSubmit}
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      )}
      {exams !== null && (
        <Formik
          onSubmit={submitExams}
          validationSchema={Yup.object().shape({
            exam_requested: Yup.object().shape({
              id: Yup.string().required(),
              value: Yup.string().required(),
            }),
            observation: Yup.string().required(),
          })}
          initialValues={{
            exam_requested: {
              id: exams && exams.exam_requested ? exams.exam_requested.id : '',
              value:
                exams && exams.exam_requested ? exams.exam_requested.name : '',
            },
            observation: exams ? exams.observation : '',
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <div className='rowWbb'>
                <h5>Exames</h5>
              </div>
              <div className='separationForms'>
                <div className='rowWbb'>
                  <div style={{ width: '100%', position: 'relative' }}>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label>Item:</Form.Label>
                      <Form.Control
                        name='exam_requested'
                        value={values.exam_requested.value}
                        onFocus={() => setShowDataExame(true)}
                        onBlur={debounce(() => setShowDataExame(false), 500)}
                        onChange={(value) => {
                          setFieldValue('exam_requested', {
                            id: '',
                            value: value.target.value,
                          });
                          getExams(value.target.value);
                        }}
                        isInvalid={!!errors.exam_requested}
                      ></Form.Control>
                    </Form.Group>
                    {showDataExams && (
                      <div className='absoluteList'>
                        {dataExams.map((exam) => (
                          <Button
                            key={exam.id}
                            variant='light'
                            size='sm'
                            style={{ width: '100%', textAlign: 'left' }}
                            onClick={() => {
                              setFieldValue('exam_requested', {
                                id: exam.id,
                                value: exam.name,
                              });
                              setShowDataDiet(false);
                            }}
                          >
                            {exam.name}
                          </Button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className='rowWbb'>
                  <Form.Group
                    controlId='formBasicEmail'
                    style={{ width: '100%' }}
                  >
                    <Form.Label>Observações:</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows='3'
                      name='observation'
                      value={values.observation}
                      onBlur={handleBlur}
                      // onChange={e => setItemExamObs(e.target.value)}
                      onChange={handleChange}
                      isInvalid={!!errors.observation}
                    />
                  </Form.Group>
                </div>
                <div className='umpartedois'>
                  <div className='rodape'>
                    <Button
                      variant='success'
                      type='submit'
                      onClick={handleSubmit}
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      )}{' '}
    </>
  );
}

export function FormAtestado({ consultation }) {
  const [type, setType] = useState(true);
  const [days, setDays] = useState('');
  const [signature, setSignature] = useState('');
  const [showModal, setShowModal] = useState(false);
  const refSignature = useRef({});
  const dateFormat = format(new Date(), `dd 'de' MMMM 'de' Y`, {
    locale: ptBR,
  });

  const clear = () => refSignature.current.clear();

  const save = () => {
    setSignature(
      refSignature.current.getTrimmedCanvas().toDataURL('image/png')
    );
    setShowModal(false);
  };

  const styles = StyleSheet.create({
    page: {
      padding: 40,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    content: {
      marginTop: 100,
      marginBottom: 100,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    line: {
      position: 'absolute',
      width: '50%',
      bottom: 0,
      border: '0.5px solid',
    },
    footer: {
      marginTop: 20,
    },
  });

  const Attestation = () => (
    <PDFViewer className='pdfviewer'>
      <Document>
        <Page size='A4' style={styles.page}>
          <View>
            <Text>Atestado</Text>
          </View>
          <View style={styles.content}>
            <Text>
              Atesto para os devidos fins, que o(a) sr.(a){' '}
              {consultation.patient.name}, compareceu a esta consulta Médica,
              onde verificamos a impossibilidade de trabalhar por: {days}{' '}
              dia(s), a partir desta data. CID: {consultation.cid}
            </Text>
          </View>
          <View
            style={{
              position: 'relative',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                position: 'absolute',
                height: '60px',
                top: '12px',
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {signature.length > 0 && <Image src={signature} />}
            </View>
            <View style={styles.line}>
              <Text>--------------------------------------------</Text>
            </View>
          </View>
          <View style={styles.footer}>
            <Text>Recife, {dateFormat}</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );

  const Declaration = () => (
    <PDFViewer className='pdfviewer'>
      <Document>
        <Page size='A4' style={styles.page}>
          <View>
            <Text>Declaração</Text>
          </View>
          <View style={styles.content}>
            <Text>
              Declaramos para aos devidos fins, que o(a) Sr.(a){' '}
              {consultation.patient.name}, portador do cpf de número{' '}
              {consultation.patient.cpf} foi atendido(a) por este profissional
              no dia{' '}
              {format(new Date(), `dd 'de' MMMM 'de' Y`, { locale: ptBR })} para
              realização de consulta
            </Text>
          </View>
          <View
            style={{
              position: 'relative',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                position: 'absolute',
                height: '60px',
                top: '12px',
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {signature.length > 0 && <Image src={signature} />}
            </View>
            <View style={styles.line}>
              <Text>--------------------------------------------</Text>
            </View>
          </View>
          <View style={styles.footer}>
            <Text>Recife, {dateFormat}</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );

  return (
    <>
      <div className='umpartedois'>
        <div className='ateste'>
          <div>
            <p>Atestado</p>
            <InputGroup.Radio checked={type} onClick={() => setType(!type)} />
            <p>Declaração</p>
            <InputGroup.Radio checked={!type} onClick={() => setType(!type)} />
          </div>
        </div>

        {type && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 20,
                marginBottom: 20,
                width: '100%',
              }}
            >
              <Form.Group controlId='formBasicEmail' style={{ width: '100%' }}>
                <Form.Label>Dias de impossibilidade</Form.Label>
                <Form.Control
                  name='days'
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                />
              </Form.Group>
            </div>
            <Attestation />
          </>
        )}

        {!type && <Declaration />}

        <div className='btss'>
          <Button variant='primary' onClick={() => setShowModal(true)}>
            Assinar
          </Button>
        </div>
      </div>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#ededed' }}>
          <SignatureCanvas
            ref={refSignature}
            canvasProps={{
              width: window.innerWidth - window.innerWidth * 0.5,
              height: window.innerHeight - window.innerHeight * 0.7,
              className: 'sigCanvas',
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={save}>Confirmar</Button>
          <Button onClick={clear}>Limpar</Button>
          <Button onClick={() => setShowModal(false)}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function FormHistorico(props) {
  const [historico, setHistorico] = useState([]);

  const [consultas, setConsultas] = useState([]);

  const [btns, setBtns] = useState({});

  const [info, setInfo] = useState();

  useEffect(() => {
    async function getHistory() {
      const data = await api
        .get(`/patients/${props.consultation.patient.id}`)
        .then((resp) => {
          setHistorico(resp.data.patient_consultations);
        });
    }
    getHistory();
  }, [props.consultation.patient.id]);

  useEffect(() => {
    var array = [];
    var btn = {};
    async function getConsultas() {
      historico.map(async (id) => {
        await api.get(`/consultations/${id.id}/`).then((resp) => {
          array = [...array, resp.data];
          setConsultas(array);
          id = resp.data.id;
          btn[id] = true;
          setBtns(btn);
        });
      });
    }

    if (historico) {
      getConsultas();
    }
  }, [historico]);

  function handleDate(date) {
    return (
      date.substring(8, 10) +
      ' / ' +
      date.substring(5, 7) +
      ' / ' +
      date.substring(0, 4)
    );
  }

  function handleDiagnostico(id) {
    var diagnostico;
    for (var i = 0; i < consultas.length; i++) {
      if (id === consultas[i].id) {
        diagnostico = consultas[i].diagnostic_hypothesis;
      }
    }

    return diagnostico;
  }

  function showHistory(id) {
    let bt = [];

    consultas.map((con) => (bt[con.id] = false));

    if (btns[id] === false) {
      bt[id] = true;
    } else {
      bt[id] = false;
    }

    setBtns(bt);

    var consul = [];

    for (let i = 0; i < consultas.length; i++) {
      if (consultas[i].id === id) {
        consul = consultas[i];
      }
    }

    setInfo(
      <>
        <tr className='info-consulta'>
          <td colSpan='3'>Tipo de consulta: </td>
          <td colSpan='3'>{consul.consultation_type}</td>
        </tr>
        <tr className='info-consulta'>
          <td colSpan='3'>Tempo de duração da consulta: </td>
          <td colSpan='3'>{consul.consultation_duration_type} minutos</td>
        </tr>
        <tr className='info-consulta'>
          <td colSpan='3'>Status: </td>
          <td colSpan='3'>{consul.status}</td>
        </tr>
        <tr className='info-consulta'>
          <td colSpan='3'>Descrição: </td>
          <td colSpan='3'>{consul.description}</td>
        </tr>
        <tr className='info-consulta'>
          <td colSpan='3'>Peso: </td>
          <td colSpan='3'>{consul.weight}</td>
        </tr>
        <tr className='info-consulta'>
          <td colSpan='3'>Altura: </td>
          <td colSpan='3'>{consul.height}</td>
        </tr>
        <tr className='info-consulta'>
          <td colSpan='3'>IMC: </td>
          <td colSpan='3'>{consul.imc}</td>
        </tr>
        <tr className='info-consulta'>
          <td colSpan='3'>Resultado: </td>
          <td colSpan='3'>{consul.result}</td>
        </tr>
        <tr className='info-consulta'>
          <td colSpan='3'>Observação: </td>
          <td colSpan='3'>{consul.observation}</td>
        </tr>
        <tr className='info-consulta'>
          <td colSpan='3'>Condutor: </td>
          <td colSpan='3'>{consul.conduct}</td>
        </tr>
      </>
    );
  }

  return (
    <>
      <div className='seteparteum'>
        <h5>Atendimentos</h5>
      </div>

      <div className='tableResponsive'>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr className='ticelula'>
              <th>Atendimento</th>
              <th>Data e Hora</th>
              <th>Especialidade</th>
              <th>Nº conselho</th>
              <th>Nome Profissional</th>
              <th>Diagnóstico</th>
            </tr>
          </thead>
          {historico.map((hist) => (
            <tbody key={hist.id}>
              <tr
                className='cocelula'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  showHistory(hist.id);
                }}
              >
                <td>{hist.id}</td>
                <td>{handleDate(hist.date)}</td>
                <td>{hist.doctor.specialty ? hist.doctor.specialty[0].name : ''}</td>
                <td>{hist.doctor.medical_register}</td>
                <td>{hist.doctor.name}</td>
                <td>{handleDiagnostico(hist.id)}</td>
              </tr>
              {btns[hist.id] ? info : null}
            </tbody>
          ))}
        </table>
      </div>
    </>
  );
}
