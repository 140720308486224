import React, { useState } from 'react';
import '../../pages/HomeDashboard/dashboard.css';
import { formatISO } from 'date-fns';
import {
    Table,
    TableContainer
} from './style';

const meses = {
    '01': 'Janeiro',
    '02': 'Fevereiro',
    '03': 'Março',
    '04': 'Abril',
    '05': 'Maio',
    '06': 'Junho',
    '07': 'Julho',
    '08': 'Agosto',
    '09': 'Setembro',
    '01': 'Janeiro',
    '10': 'Outubro',
    '11': 'Novembro',
    '12': 'Dezembro',
};

const dic = {
    Pending: '#F2C832',
    Scheduled: '#158175',
    Denied: '#d0002a',
    Done: 'blue',
};

const statusDic = {
    Pending: 'Pendente',
    Scheduled: 'Agendada',
    Denied: 'Negada',
    Done: 'Realizada',
};

function formatDate(date) {
    if (date) {
        let vars = date.split('T');
        let time = vars[1].slice(0, vars[1].length - 1);
        let dateEls = vars[0].split('-');
        return `Dia ${dateEls[2]} de ${meses[dateEls[1]]} de ${dateEls[0]
            } às ${time.slice(0, 8)}.`;
    } else return '';
}

function ConsultList({ consultations, nameFilter, dateFilter, setCons }) {
    const [patients, setPatients] = useState(consultations);

    function ShowPatients() {
        return (
            patients
                .filter((consultation) => {
                    if ((!nameFilter || consultation.patient.name.toLowerCase().includes(nameFilter.toLowerCase().trim()))
                        && (!dateFilter || consultation.date.slice(0, 10).includes(formatISO(dateFilter).slice(0, 10)))) return consultation;
                })
                .map((consultation) => {
                    var cons = Object(consultation.patient);
                    let color = dic[consultation.status];

                    return (
                        <tr key={consultation.id}>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <img
                                        src={cons.picture}
                                        alt='icone-profile'
                                        className='icone-profile'
                                    />
                                    <div>{cons.name}</div>
                                </div>
                            </td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'left', color: '#007469', lineHeight: '1rem' }}>
                                    <i style={{ paddingRight: '.5rem' }} className='fa fa-calendar space-p' />
                                    {formatDate(consultation.date)}
                                </div>
                            </td>
                            <td>
                                <p style={{ color: color, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {statusDic[consultation.status]}
                                </p>
                            </td>
                            <td>
                                <button
                                    onClick={() => setCons(consultation)}
                                    className='botao-do-status'
                                >
                                    VER DETALHES{' '}
                                </button>
                            </td>
                        </tr>
                    );
                })
        );
    }


    return (
        patients.filter((consultation) => {
            if ((!nameFilter || consultation.patient.name.toLowerCase().includes(nameFilter.toLowerCase().trim()))
                && (!dateFilter || consultation.date.slice(0, 10).includes(formatISO(dateFilter).slice(0, 10)))) return consultation;
        }).length ? (
            <TableContainer>
                <Table>
                    <thead>
                        <tr>
                            <th className='nome myth'>Nome do Paciente</th>
                            <th className='data-consulta myth'>Data da Consulta</th>
                            <th className='status-tamanho myth'>Status da consulta</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ShowPatients />
                    </tbody>
                </Table>
            </TableContainer>
        ) : (
            <table>
                <tbody>
                    <tr>
                        <td colSpan='4' className='text-center'>
                            <h1 className='myh1'>Sem pacientes no momento.</h1>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    );
}

export default ConsultList;