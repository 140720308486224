import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import api from '../../services/api';
import { isAuthenticated, getUserId } from '../../services/auth';

import { FormContent, InputComponent3 } from './styles';

import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from '@trendmicro/react-sidenav';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { Sidebar } from '../Sidebar';
import { NavBar } from '../Navbar';
import '../Navbar/navbar.css';
import './css/editar.css';
import loginstyles from '../SignIn/css/login.module.css';
import upstyles from '../SignUp/css/signup.module.css';
import styles from './css/editar.module.css';
import { Container } from '../SignIn/styles.js';

import NewSideBar from '../NewSideBar';
import InputMask from 'react-input-mask';
import MaskedInput from 'react-maskedinput';
import '../SignUp/mask';
import NumberFormat from 'react-number-format';

import {
  FormControl,
  Form,
  Button,
  Col,
  Table,
  ButtonGroup,
  Card,
  Tab,
  Tabs,
  InputGroup,
  Modal,
  ButtonToolbar,
  Row,
  Image,
} from 'react-bootstrap';

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.updatePicture = this.updatePicture.bind(this);
    // this.updateDocFront = this.updateDocFront.bind(this);
    // this.updateDocBack = this.updateDocBack.bind(this);
  }

  state = {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phonenumber: '',
    is_doctor: false,
    council: {},
    medical_register: 1,
    category: {},
    specialty: {},
    accept_medical_agreement: true,
    medical_insurances: [],
    doctor_agenda: [],
    picture: '',
    error: '',
    specialties: [],
    categories: [],
    medicalInsurances: [],
    councils: [],
    states: [],
    council_states: [],
    state: '',
    council_state: '',
    filename: '',
    picture: null,
    is_doctor: false,
    birthdate: '',
    newpicture: null,
    nameupd: '',
    old_password: '',
    price: '',
    account_type: '',
    account_types: [
      {
        value: 'conta_corrente',
        name: 'Conta Corrente',
      },
      {
        value: 'conta_poupanca',
        name: 'Conta Poupanca',
      },
      {
        value: 'conta_corrent_conjunta',
        name: 'Conta Corrente Conjunta',
      },
      {
        value: 'conta_poupanca_conjunta',
        name: 'Conta Poupanca Conjunta',
      },
    ],
    bankCode: '',
    accountNumber: '',
    accountDV: '',
    agencyNumber: '',
    agencyDV: '',
    legalName: '',
    document: '',
    showAccountForm: false,
    infoMesage: '',
  };

  handleUpdate = async (e) => {
    e.preventDefault();
    let foto = this.refs.foto.files[0];
    let data = new FormData();

    data.append('name', this.state.nameupd);
    data.append('email', this.state.email);
    data.append('birthdate', this.state.birthdate);
    data.append('phonenumber', this.state.phonenumber);
    data.append(
      'accept_medical_agreement',
      this.state.accept_medical_agreement
    );
    data.append('price', parseFloat(this.state.price.replace('R$', '')));

    if (this.state.accept_medical_agreement) {
      //data.append('medical_insurances', [])
    }

    if (this.state.password != this.state.password_confirmation) {
      this.setState({ error: 'A senha precisa ser confirmada corretamente' });
      return;
    } else if (this.state.password.length > 0) {
      if (!this.state.old_password.length) {
        this.setState({
          error: 'Informe a senha antiga para realizar uma troca de senha',
        });
        return;
      }

      data.append('password', this.state.password);
      data.append('password_confirmation', this.state.password_confirmation);
      data.append('old_password', this.state.old_password);
    }

    if (foto) {
      data.append('picture', foto);
    }

    try {
      const response = await api.patch(
        '/doctors/' + String(getUserId()) + '/',
        data
      );
      window.location.reload();
      //xD
    } catch (err) {
      console.log(err);
      this.setState({
        error: Object.values(err.response.data)[0],
      });
    }
  };

  getDoctorInfo = () => {
    api.get('/doctors/' + String(getUserId()) + '/').then((res) => {
      const data = res.data;
      // console.log(data);
      this.setState({
        name: data.name,
        email: data.email,
        phonenumber: data.phonenumber,
        category: data.category,
        specialty: data.specialty,
        accept_medical_agreement: data.accept_medical_agreement,
        medical_insurances: data.medical_insurances,
        picture: data.picture,
        council: data.council,
        medical_register: data.medical_register,
        is_doctor: data.is_doctor,
        birthdate: data.birthdate,
        newpicture: data.picture,
        nameupd: data.name,
        doctor_agenda: data.doctor_agenda,
        price: parseInt(data.price).toFixed(2),
      });
    });
  };
  getSpecialties() {
    let initialSpecialties = [];
    api.get('/specialties/').then((res) => {
      const data = res.data;
      initialSpecialties = data.map((specialty) => {
        return specialty;
      });
      // console.log(initialSpecialties);
      this.setState({
        specialties: initialSpecialties,
      });
    });
  }

  getInsurances() {
    let initialInsurances = [];
    api.get('/medical_insurances/').then((res) => {
      const data = res.data;
      initialInsurances = data.map((specialty) => {
        return specialty;
      });
      this.setState({
        medicalInsurances: initialInsurances,
        medical_insurances: [],
      });
    });
  }

  getStates() {
    let initialInsurances = [];
    api.get('/states/').then((res) => {
      const data = res.data;
      initialInsurances = data.map((specialty) => {
        return specialty;
      });
      this.setState({
        states: initialInsurances,
        state: initialInsurances[0].id,
      });
    });
  }

  getCouncils() {
    let initialInsurances = [];
    api.get('/councils/').then((res) => {
      const data = res.data;
      initialInsurances = data.map((specialty) => {
        return specialty;
      });
      this.setState({
        councils: initialInsurances,
      });
    });
  }

  findCouncilState(councilstates, council, state) {
    for (var i = 0; i < councilstates.length; i++) {
      if (
        councilstates[i].state.id == state &&
        councilstates[i].council.id == council
      )
        return councilstates[i].id;
    }
  }

  getCouncilStates() {
    let initialInsurances = [];
    api.get('/council_states/').then((res) => {
      const data = res.data;
      initialInsurances = data.map((specialty) => {
        return specialty;
      });
      this.setState({
        council_states: initialInsurances,
        council_state: this.findCouncilState(
          initialInsurances,
          this.state.state,
          this.state.council
        ),
      });
    });
  }

  getCategories() {
    let initialCategories = [];
    api.get('/medical_categories/').then((res) => {
      const data = res.data;
      initialCategories = data.map((category) => {
        return category;
      });
      // console.log(initialCategories);
      this.setState({
        categories: initialCategories,
        category: initialCategories[0].id,
      });
    });
  }

  handleClick(e) {
    this.refs.foto.click();
  }

  updatePicture = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ newpicture: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  updateDocFront = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ newdocfront: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  updateDocBack = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ newdocback: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  rebuildDate(param, to) {
    if (to == 1) {
      let vari = param.split('-');
      let varano = vari[0];
      let varmes = vari[1];
      let vardia = vari[2];
      return `${vardia}/${varmes}/${varano}`;
    } else {
      let vari = param.split('/');
      return `${vari[2]}-${vari[1]}-${vari[0]}`;
    }
  }

  componentDidMount() {
    this.getDoctorInfo();
    this.getSpecialties();
    this.getCategories();
    this.getInsurances();
    this.getCouncils();
    this.getStates();
    this.getCouncilStates();
  }

  async cadastrarConta() {
    var id = getUserId();
    try {
      await api
        .post('/bank_accounts/', {
          doctor: id,
          type_account: this.state.account_type,
          bank_code: this.state.bankCode,
          account_code: this.state.accountNumber,
          account_dv: this.state.accountDV,
          agency: this.state.agencyNumber,
          agency_dv: this.state.agencyDV,
          legal_name: this.state.legalName,
          document: this.state.document,
        })
        .then(() =>
          this.setState({ infoMesage: 'Conta Cadastrada com Sucesso' })
        );
    } catch (err) {
      console.log(err);
      this.setState({
        infoMesage: 'Erro ao cadastrar. Verifique as informações.',
      });
    }
  }

  render() {
    let specialties = this.state.specialties;
    let optionItems = specialties.map((specialty) => (
      <option value={specialty.id} key={specialty.id}>
        {specialty.name}
      </option>
    ));
    let categories = this.state.categories;
    let optionCategories = categories.map((category) => (
      <option value={category.id} key={category.id}>
        {category.name}
      </option>
    ));
    let medicalInsurances = this.state.medicalInsurances;
    let optionMedicalInsurances = medicalInsurances.map((insurance) => (
      <option value={insurance.id} key={insurance.id}>
        {insurance.name}
      </option>
    ));

    let states = this.state.states;
    let optionstates = states.map((state) => (
      <option value={state.id} key={state.id}>
        {state.sigla}
      </option>
    ));

    let councils = this.state.councils;
    let optioncouncils = councils.map((council) => (
      <option value={council.id} key={council.id}>
        {council.sigla}
      </option>
    ));

    let opTypes = this.state.account_types;
    let optionsAT = opTypes.map((types) => (
      <option values={types.value} key={types.value}>
        {types.name}
      </option>
    ));

    return (
      <div className='mydiv wrapper' style={{ position: 'relative' }}>
        <NavBar state={this.state}></NavBar>
        <div className='newSideContext'>
          <NewSideBar getDoctorInfo={this.getDoctorInfo}></NewSideBar>
          <div className='mycontent'>
            <div className='container'>
              <div className='card'>
                <div className='card-body'>
                  <form onSubmit={this.handleUpdate}>
                    <div className='row' style={{ padding: '1%' }}>
                      <table>
                        <tr>
                          <td>
                            <img
                              src={this.state.newpicture}
                              className='profile'
                            />
                          </td>
                          <td>
                            <h2 id='meunome'>Dr. {this.state.name}</h2>
                            <p>
                              selecione uma foto do seu computador
                              <br /> e deixe seu perfil mais personalizado
                            </p>
                            <div>
                              <button
                                type='button'
                                onClick={this.handleClick}
                                className='btn btn-default mybtn'
                              >
                                <div>
                                  <i className='fa fa-camera' />
                                  &nbsp;Adicionar Foto
                                </div>
                                <input
                                  onChange={this.updatePicture}
                                  type='file'
                                  accept='image/*'
                                  ref='foto'
                                  style={{ width: '100%' }}
                                ></input>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className='row' style={{ marginTop: '2%' }}>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Nome</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-user'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className=' myinput unico-input'
                            name='name'
                            value={this.state.nameupd}
                            autoComplete='off'
                            onChange={(e) =>
                              this.setState({ nameupd: e.target.value })
                            }
                          />
                        </InputGroup>
                      </div>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <br />
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Checkbox
                              name='q1_myOptions[]'
                              checked={this.state.is_doctor}
                              disabled
                            />
                          </InputGroup.Prepend>
                          <FormControl value='Não sou médico, sou outro profissional de saúde' />
                        </InputGroup>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Email</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className=' fa fa-envelope'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='email'
                            className='unico-input myinput'
                            value={this.state.email}
                            autoComplete='off'
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </InputGroup>
                        {/* <div className="form-group">
                                            <label className='unico-label'>Email</label>
                                            <i className='icon-user1 fa fa-envelope'></i>
                                            <input type="email" className="unico-input myinput"
                                                value={this.state.email} autoComplete='off' onChange={e => this.setState({ email: e.target.value })} />
                                        </div> */}
                      </div>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Tipo de Profissional</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='  fa fa-user'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className=' myinput unico-input'
                            name='name'
                            value={Object(this.state.category).name}
                            autoComplete='off'
                            readOnly
                          />
                        </InputGroup>
                        {/* <div className="form-group">
                                            <label className='unico-label'>Tipo de Profissional</label>
                                            <i className='icon-data fa fa-user' />
                                            <input type="text" className=" myinput unico-input" name="name"
                                                value={Object(this.state.category).name} autoComplete='off' readOnly />
                                        </div> */}
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Data de Nascimento</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-calendar'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <MaskedInput
                            {...this.props}
                            mask='11/11/1111'
                            type='text'
                            className='unico-input myinput3'
                            name='name'
                            value={this.rebuildDate(this.state.birthdate, 1)}
                            autoComplete='off'
                            onChange={(e) =>
                              this.setState({
                                birthdate: this.rebuildDate(e.target.value, 2),
                              })
                            }
                          />
                        </InputGroup>
                        {/* <div className="form-group">
                                            <label className='unico-label'>Data de Nascimento</label>
                                            <i className='icon-data fa fa-calendar' />
                                            <MaskedInput {...this.props} mask="11/11/1111" type="text" className=" myinput unico-input" name="name"
                                                value={this.rebuildDate(this.state.birthdate, 1)} autoComplete='off' onChange={e => this.setState({ birthdate: this.rebuildDate(e.target.value, 2) })} />
                                        </div> */}
                      </div>
                      <div
                        className='col-xs-12 col-sm-3 col-md-2'
                        style={{ textAlign: 'left' }}
                      >
                        <InputGroup>
                          <Form.Label>Código</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-stethoscope'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className=' myinput unico-input'
                            name='name'
                            value={this.state.medical_register}
                            autoComplete='off'
                            readOnly
                          />
                        </InputGroup>
                        {/* <label className='unico-label2'>Código </label>
                                        <i className='icon-steto fa fa-stethoscope' />
                                        <input type="text" className=" myinput2 unico-input" name="name"
                                            value={this.state.medical_register} autoComplete='off' readOnly /> */}
                      </div>
                      <div className='col-xs-12 col-sm-3 col-md-2'>
                        <br />
                        <FormControl
                          type='text'
                          className='myinput2 unico-input '
                          name='name'
                          value={Object(this.state.council.council).sigla}
                          autoComplete='off'
                          readOnly
                        />
                      </div>
                      <div className='col-xs-12 col-sm-3 col-md-2'>
                        <br />
                        <FormControl
                          type='text'
                          className='myinput2 unico-input'
                          name='name'
                          value={Object(this.state.council.state).sigla}
                          autoComplete='off'
                          style={{ float: 'right' }}
                          readOnly
                        />
                        {/* <input type="text" className=" myinput3 unico-input2" name="name"
                                            value={Object(this.state.council.council).sigla} autoComplete='off' readOnly />
                                        <input type="text" className=" myinput3 unico-input2" name="name"
                                            value={Object(this.state.council.state).sigla} autoComplete='off' style={{ "float": "right" }} readOnly /> */}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Telefone</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-phone'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <MaskedInput
                            {...this.props}
                            mask='+55 (11) 9 1111-1111'
                            type='text'
                            className='unico-input myinput3'
                            value={this.state.phonenumber}
                            autoComplete='off'
                            onChange={(e) =>
                              this.setState({ phonenumber: e.target.value })
                            }
                          />
                        </InputGroup>
                        {/* <div className="form-group">
                      <label className='unico-label'>Telefone</label>
                      <i className='icon-user1 fa fa-phone' />
                      <MaskedInput  {...this.props} mask="+55 (11) 9 1111-1111" type="text" className="unico-input myinput"
                        value={this.state.phonenumber} autoComplete='off' onChange={e => this.setState({ phonenumber: e.target.value })} />
                    </div> */}
                      </div>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Especialidade</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-stethoscope'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className='unico-input myinput'
                            value={Object(this.state.specialty[0]).name}
                            autoComplete='off'
                            readOnly
                          />
                        </InputGroup>
                        {/* <div className="form-group">
                      <label className='unico-label'>Especialidade </label>
                      <i className='icon-user1 fa fa-stethoscope' />
                      <input type="text" className="unico-input myinput"
                        value={Object(this.state.specialty[0]).name} autoComplete='off' readOnly />
                    </div> */}
                      </div>
                    </div>

                    <div className='row'>
                      <Form>
                        <div className='mb-3'></div>
                      </Form>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Aceita convênio médico?</Form.Label>
                          <Form.Check
                            inline
                            type='radio'
                            label='Sim'
                            onChange={(e) =>
                              this.setState({ accept_medical_agreement: true })
                            }
                            checked={this.state.accept_medical_agreement}
                            value='sim'
                            name='inlineRadioOptions'
                            id='inlineRadio1'
                          />
                          <Form.Check
                            inline
                            type='radio'
                            label='Não'
                            value='nao'
                            name='inlineRadioOptions'
                            id='inlineRadio2'
                            onChange={(e) =>
                              this.setState({ accept_medical_agreement: false })
                            }
                            checked={!this.state.accept_medical_agreement}
                          />
                        </InputGroup>
                        {/* <label>Aceita convênio médico?</label>
                    <div className="form-check form-check-inline" style={{ "marginLeft": "3%" }}>
                      <input className="form-check-input radio-my" onChange={(e) => this.setState({ accept_medical_agreement: true })} checked={this.state.accept_medical_agreement} value="sim" type="radio" name="inlineRadioOptions" id="inlineRadio1" />
                      <label className="form-check-label" htmlFor="inlineRadio1">Sim</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input radio-my" value="nao" type="radio" name="inlineRadioOptions" id="inlineRadio2" onChange={(e) => this.setState({ accept_medical_agreement: false })} checked={!this.state.accept_medical_agreement} />
                      <label className="form-check-label" htmlFor="inlineRadio2">Não</label>
                    </div> */}
                      </div>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Convênios</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-stethoscope'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>

                          <select
                            onChange={(e) =>
                              this.setState({
                                medical_insurances: [e.target.value],
                              })
                            }
                            className=' unico-input'
                            disabled={!this.state.accept_medical_agreement}
                          >
                            {optionMedicalInsurances}
                          </select>
                        </InputGroup>
                        {/* <div className="form-group">
                      <label className='unico-label'>Convênios </label>
                      <i className='icon-user1 fa fa-stethoscope' />
                      <select onChange={e => this.setState({ medical_insurances: [e.target.value] })} className="myinput unico-input" disabled={!this.state.accept_medical_agreement}>
                        {optionMedicalInsurances}
                      </select>
                    </div> */}
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Preço da consulta</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-money'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>

                          <NumberFormat
                            thousandSeparator={false}
                            decimalSeparator={'.'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={'R$'}
                            className='unico-input myinput3'
                            placeholder='Preço base da consulta'
                            value={this.state.price}
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              // formattedValue = $2,223
                              // value ie, 2223
                              this.setState({ price: formattedValue });
                            }}
                          />
                        </InputGroup>
                        {/* <div className="form-group">
                      <label className='unico-label'>Preço da consulta</label>
                      <div className='icon-user1 fa fa-money'></div>
                      <NumberFormat thousandSeparator={false} decimalSeparator={"."} decimalScale={2} fixedDecimalScale={true} prefix={'R$'} className="unico-input myinput" placeholder="Preço base da consulta" value={this.state.price} onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        this.setState({ price: formattedValue })
                      }} />
                    </div> */}
                      </div>
                      {/* <div className="col-xs-12 col-sm-6 col-md-6">

                  </div> */}
                    </div>

                    <div id='senha' className='row'>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Senha antiga</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-lock'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='password'
                            className='unico-input myinput'
                            placeholder='Informe a senha antiga'
                            autoComplete='off'
                            value={this.old_password}
                            onChange={(e) =>
                              this.setState({ old_password: e.target.value })
                            }
                          />
                        </InputGroup>
                        {/* <div className="form-group">
                      <label className='unico-label'>Senha antiga </label>

                      <i className='icon-user1 fa fa-lock' />
                      <input type="password" className="unico-input myinput"
                        placeholder="Informe a senha antiga" autoComplete='off' value={this.old_password} onChange={e => this.setState({ old_password: e.target.value })} />
                    </div> */}
                      </div>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Nova senha</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-lock'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='password'
                            className='unico-input myinput'
                            placeholder='Insira a nova senha'
                            autoComplete='off'
                            value={this.password}
                            onChange={(e) =>
                              this.setState({ password: e.target.value })
                            }
                          />
                        </InputGroup>
                        {/* <div className="form-group">
                      <label className='unico-label'>Nova senha </label>
                      <i className='icon-user1 fa fa-lock' />
                      <input type="password" className="unico-input myinput"
                        placeholder="Insira a nova senha" autoComplete='off' value={this.password} onChange={e => this.setState({ password: e.target.value })} />
                    </div> */}
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Confirmação da nova senha</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-lock'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='password'
                            className='unico-input myinput'
                            placeholder='Confirme a nova senha'
                            autoComplete='off'
                            value={this.password_confirmation}
                            onChange={(e) =>
                              this.setState({
                                password_confirmation: e.target.value,
                              })
                            }
                          />
                        </InputGroup>
                        {/* <div className="form-group">
                      <label className='unico-label'>Confirmação da nova senha </label>
                      <i className='icon-user1 fa fa-lock' />
                      <input type="password" className="unico-input myinput"
                        placeholder="Confirme a nova senha" autoComplete='off' value={this.password_confirmation} onChange={e => this.setState({ password_confirmation: e.target.value })} />
                    </div> */}
                      </div>
                      <div
                        className='col-xs-12 col-sm-6 col-md-6'
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <div className='form-group'>
                          <input
                            type='submit'
                            value='SALVAR'
                            className='btn btn-success'
                            style={{ width: '70%', background: '#007469' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row danger'>
                      <h4 style={{ textAlign: 'center' }}>{this.state.error}</h4>
                    </div>
                  </form>
                </div>

                {/* Conta bancária */}
                <div
                  className='row'
                  style={{ paddingLeft: '2%', paddingRight: '2%' }}
                >
                  <div className='form-group'>
                    <input
                      value='Conta Bancária'
                      className='btn btn-light'
                      style={{
                        width: 'auto',
                        background: '#ccc',
                        marginLeft: '2%',
                        marginBottom: '2%',
                        fontSize: '14px',
                      }}
                      onClick={() =>
                        this.state.showAccountForm === true
                          ? this.setState({ showAccountForm: false })
                          : this.setState({ showAccountForm: true })
                      }
                    />
                  </div>
                </div>

                {this.state.showAccountForm ? (
                  <>
                    <div
                      className='row'
                      style={{ paddingLeft: '2%', paddingRight: '2%' }}
                    >
                      <div className='col-xs-12 col-sm-2 col-md-2'>
                        <InputGroup>
                          <Form.Label>Código do Banco</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-university'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className='unico-input myinput'
                            placeholder='000'
                            autoComplete='off'
                            value={this.state.bankCode}
                            onChange={(e) =>
                              this.setState({
                                bankCode: e.target.value.replace(/\D/g, ''),
                              })
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>

                    <div
                      className='row'
                      style={{ paddingLeft: '2%', paddingRight: '2%' }}
                    >
                      <div className='col-xs-12 col-sm-3 col-md-3'>
                        <InputGroup>
                          <Form.Label>Conta</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-user'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className='unico-input myinput'
                            placeholder='Número da Conta'
                            autoComplete='off'
                            value={this.state.accountNumber}
                            onChange={(e) =>
                              this.setState({
                                accountNumber: e.target.value.replace(/\D/g, ''),
                              })
                            }
                          />
                        </InputGroup>
                      </div>
                      <div className='col-xs-12 col-sm-3 col-md-3'>
                        <InputGroup>
                          <Form.Label>Dígito da Conta</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-user'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className='unico-input myinput'
                            placeholder='Dígito'
                            autoComplete='off'
                            value={this.state.accountDV}
                            onChange={(e) =>
                              this.setState({
                                accountDV: e.target.value.replace(/\D/g, ''),
                              })
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>
                    <div
                      className='row'
                      style={{ paddingLeft: '2%', paddingRight: '2%' }}
                    >
                      <div className='col-xs-12 col-sm-3 col-md-3'>
                        <InputGroup>
                          <Form.Label>Agência</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-user'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className='unico-input myinput'
                            placeholder='Número da Agência'
                            autoComplete='off'
                            value={this.state.agencyNumber}
                            onChange={(e) =>
                              this.setState({
                                agencyNumber: e.target.value.replace(/\D/g, ''),
                              })
                            }
                          />
                        </InputGroup>
                      </div>
                      <div className='col-xs-12 col-sm-3 col-md-3'>
                        <InputGroup>
                          <Form.Label>Dígito da Agência</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-user'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className='unico-input myinput'
                            placeholder='Dígito'
                            autoComplete='off'
                            value={this.state.agencyDV}
                            onChange={(e) =>
                              this.setState({
                                agencyDV: e.target.value.replace(/\D/g, ''),
                              })
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>
                    <div
                      className='row'
                      style={{ paddingLeft: '2%', paddingRight: '2%' }}
                    >
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Nome Legal</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-id-card'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className='unico-input myinput'
                            placeholder='Nome Completo'
                            autoComplete='off'
                            value={this.state.legalName}
                            onChange={(e) =>
                              this.setState({
                                legalName: e.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>
                    <div
                      className='row'
                      style={{ paddingLeft: '2%', paddingRight: '2%' }}
                    >
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Documento</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-id-card'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='text'
                            className='unico-input myinput'
                            placeholder='CPF/CNPJ'
                            autoComplete='off'
                            value={this.state.document}
                            onChange={(e) =>
                              this.setState({
                                document: e.target.value.replace(/\D/g, ''),
                              })
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>

                    <div
                      className='row'
                      style={{
                        paddingLeft: '2%',
                        paddingRight: '2%',
                        marginBottom: '2%',
                      }}
                    >
                      <div className='col-xs-12 col-sm-6 col-md-6'>
                        <InputGroup>
                          <Form.Label>Tipo da Conta</Form.Label>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className='fa fa-university'></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>

                          <select
                            onChange={(e) =>
                              this.setState({
                                account_type: e.target.value,
                              })
                            }
                            className=' unico-input'
                          // disabled={!this.state.accept_medical_agreement}
                          >
                            {optionsAT}
                          </select>
                        </InputGroup>
                      </div>
                    </div>
                    <div
                      className='row'
                      style={{ paddingLeft: '2%', paddingRight: '2%' }}
                    >
                      <div className='form-group'>
                        <input
                          value='Adicionar'
                          className='btn btn-success'
                          style={{
                            background: '#007469',
                            marginLeft: '2%',
                            marginBottom: '2%',
                            fontSize: '14px',
                          }}
                          disabled={
                            this.state.bankCode === '' ||
                            this.state.accountNumber === '' ||
                            this.state.accountDV === '' ||
                            this.state.agencyNumber === '' ||
                            this.state.legalName === '' ||
                            this.state.document === ''
                          }
                          onClick={() => this.cadastrarConta()}
                        />
                      </div>
                    </div>
                    {this.state.infoMesage !== '' ? (
                      <span style={{ margin: '2%' }}>
                        {this.state.infoMesage}
                      </span>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditProfile);
