import React, { Component } from "react";
import { Modal, Button, Pagination } from 'react-bootstrap';

import "../pages/ModalComponent/modal.css";

class PaginationComponent extends Component {
  constructor() {
    super();
    this.state = {
      currentPage: 1,
      todosPerPage: 10
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id)
    });
  }

  render() {
    const { currentPage, todosPerPage } = this.state;
    const { todos, func } = this.props;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos.map((horario, index) => {
      return (
        <div className="semana">
          <div className="iconeCalendario"></div>
          <div className="intervaloHorario"><p>{horario.time}</p></div>
          <i id={horario.id} className="iconeExcluir" onClick={(e) => func(horario.id)}></i>
        </div>
      );
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(todos.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <Pagination.Item key={number} id={number}  active={number === currentPage} onClick={this.handleClick}>
          {number}
        </Pagination.Item>
      );
    });

    return (
      <div>
        <ul>
          {renderTodos}
        </ul>
        <Pagination>
          {renderPageNumbers}
        </Pagination>
      </div>
    );
  }
}

export default PaginationComponent;