import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { isAuthenticated } from './services/auth';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import HomeDashboard from './pages/HomeDashboard';
import EditProfile from './pages/EditProfile';
import TokboxChat from './pages/TokboxChat';
import HistoryPage from './pages/HistoryPage';
import ReportsPage from './pages/ReportsPage';
import PaymentPage from './pages/PaymentPage';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const SecondRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/app', state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <SecondRoute exact path='/' component={SignIn} isAuthenticated />
      <SecondRoute path='/signin' component={SignIn} />
      <Route path='/signup/:token' component={SignUp} />
      <Route path='/payment' component={PaymentPage} />
      <Route path='/forgot_password' component={ForgotPassword} />
      <PrivateRoute path='/app' component={HomeDashboard} />
      <PrivateRoute path='/editprofile' component={EditProfile} />
      <PrivateRoute path='/history' component={HistoryPage} />
      <PrivateRoute path='/reports' component={ReportsPage} />
      <PrivateRoute
        name='tokboxcall'
        path='/tokboxcall/:consultation'
        component={TokboxChat}
      />
      <Route path='*' component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
