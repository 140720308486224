import styled from 'styled-components';

export const PageContainer = styled.body`
  width: 100vw;
  background: #007469;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
    width: min(75%, 1000px);
    min-height: 500px;
    max-height: min(98%, 700px);
    background: white;
    background-position: -320px 100px;
    background-size: 1000px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    flex-direction: column;
    overflow: hidden;
    color: #007469;
`;

export const TopSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 82.5%;
    width: 100%;
    padding: 2rem 0;
    overflow-y: auto;

    @media(max-width: 600px){
        padding: 5vw 0;
    }
`;

export const DataFieldsContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3rem 4rem;

    @media(max-width: 800px){
        padding: 5vw;
    }
`;

export const BottomBar = styled.div`
    background: #F2F2F2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 10%;

    @media(max-width: 600px){
        padding: 3vh 5vw;
        justify-content: space-around;        
    }
`;

export const CardFieldsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 850px){
        flex-direction: column;
        justify-content: center;
        align-items: space-around;
    }
`;

export const CardInputsContainer = styled.div`
    height: 90%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    @media(max-width: 850px){
        width: 100%;
        margin-bottom: 5vh;
    }
`;


export const NextButton = styled.button`
    outline: none;
    border: none;
    text-decoration: none;
    text-align: center;
    color: white;
    background: #007469;
    font-size: clamp(1rem, 2vw, 1.2rem);

    font-weight: 500;
    height: 2.5rem;
    width: 14rem;
    border-radius: 8px;

    @media(max-width: 600px){
        width: auto;
        height: auto;
        padding: 1vh 5vw;
    }
`;

export const PreviousButton = styled(NextButton)`
    color: #007469;
    background: transparent;
`;

export const RegularText = styled.div`
    font-weight: 600;
    font-size: clamp(1rem, 2.5vw, 1.2rem);
    text-align: center;
    height: auto;
    text-overflow: ellipsis;
    line-clamp: 1;
`;

export const FeatureText = styled(RegularText)`
    width: 70%;
    font-weight: 500;
    font-size: clamp(.75rem, 2vw, 1rem);
`;

export const FeatureTitle = styled(FeatureText)`
    font-style: italic;
    font-weight: 700;
`;

export const SectionTitle = styled(RegularText)`
    font-size: clamp(1.2rem, 3vw, 1.5rem);
`;

export const TitleText = styled.div`
    width: min(90%, 500px);
    font-weight: 700;
    font-size: clamp(1.25rem, 3vw, 1.75rem);
    text-align: center;
`;

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: .25rem 0;
`;

export const HalfInput = styled(InputContainer)`
    width: 40%;
`;

export const TextInput = styled.input`
    width: 100%;
    border: none;
    border-radius: 8px;
   box-shadow: inset 0px 1px 3px #00000050;
   padding: .5rem 1.5rem;
   font-weight: 500;
   color: #6E6E6E;
`;

export const InputDiv = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: inset 0px 1px 3px #00000050;
    padding: .5rem 1.5rem;
    background: white;
`;

export const Input = styled.input`
    width: 100%;
    border: none;
    font-weight: 500;
    color: #6E6E6E;
`;

export const InstallmentField = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: inset 0px 1px 3px #00000050;
    padding: .5rem 1.5rem;
    font-weight: 500;
    color: #6E6E6E;
    background: white;
`;

export const OptionList = styled.div`
    height: 16vh;
    width: 100%;
    box-shadow: inset 0px 1px 3px #00000050;
    background-color: white;
    overflow-y: scroll; 
    position: absolute;
    `

export const OptionListItem = styled.button`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    border: none;
    padding: .5rem 1.5rem;
    background: transparent;
    font-weight: 500;
    color: #6E6E6E;

    :hover{
        background: #F2F2F2;
    }
`

export const OptionsContainer = styled.div`
    width: 100%;
    border-radius: 8px;
    background: white;
    position:relative;
`
