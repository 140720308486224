import styled from 'styled-components';
import card from '../../assets/images/card.svg';

export const CardContainer = styled.div`
    background-image: url(${card});
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InfoContainer = styled.div`
    width: max(300px, auto);
    height: max(150px, auto);
    display: flex;
    flex-direction: column;
    padding: 5rem 3.25rem 1rem 1.5rem;

    font-size: clamp(.8rem, 2.5vw, 1.2rem);

    @media(max-width: 400px){
        padding: 3rem 3.25rem 1rem .8rem;
    }
`;

export const InfoRow = styled.div`
    width: 250px;
    height: 29px;

    font-size: clamp(.8rem, 2.5vw, 1.2rem);

    @media(max-width: 850px){
        width: 140px;
        height: 13px;
        font-size: .6rem;
    }
`;