import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from '@trendmicro/react-sidenav';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import ModalComponent from '../ModalComponent';

//import "./navbar.css"

import api from '../../services/api';
import logo from '../../assets/images/logo.svg';
import teste from '../../assets/skins/forma_1_ek3.png';
import menu from '../../assets/skins/menu.png';
import calendario from '../../assets/skins/forma_1_ek3.png';
import down from '../../assets/skins/down_2_ek1.png';
import bell from '../../assets/skins/bell.png';
import { logout, TOKEN_KEY, getToken } from '../../services/auth';
import loginstyles from '../SignIn/css/login.module.css';

let gambiarra = 'xd';

class NavbarComponent extends Component {
  state = {
    council: '',
    state: '',
  };

  componentDidMount() {
    console.log(this.props, 'baattlerite esta morto');
  }

  handleLogout = async (event) => {
    try {
      const response = await api.post(
        '/logout/',
        {},
        {
          headers: {
            Authorization: `JWT ${getToken()}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
      this.setState({
        error: 'Houve um problema deslogando.',
      });
    }

    await logout();
    window.location.reload();
  };

  render() {
    const council =
      this.props.state.council == undefined
        ? ''
        : this.props.state.council.council == undefined
        ? ''
        : this.props.state.council.council.sigla;
    const state =
      this.props.state.council == undefined
        ? ''
        : this.props.state.council.state == undefined
        ? ''
        : this.props.state.council.state.sigla;

    return (
      <nav className='navbar navbar-expand-lg navbar-expand-md navbar-light bg-light'>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarTogglerDemo01'
          aria-controls='navbarTogglerDemo01'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div
          className='collapse navbar-collapse text-center'
          id='navbarTogglerDemo01'

        >
          <img style={{margin: '1rem 0', marginLeft: '2.5rem', height: '4rem'}} src={logo} />

          <ul className='navbar-nav mr-auto mt-2 mt-lg-0 mleft'>
            <li className='nav-item hovsidebar'>
              <Link to='/' className='linksidebar'>
                <div className='nli2'>
                  <i
                    className='fa fa-fw fa-clock-o'
                    style={{ fontSize: '1.5em' }}
                  />{' '}
                  Consultas{' '}
                </div>
              </Link>
            </li>
            <li className='nav-item hovsidebar'>
              <Link to='editprofile' className='linksidebar'>
                <div className='nli2'>
                  <i
                    className='fa fa-fw fa-user'
                    style={{ fontSize: '1.5em' }}
                  />{' '}
                  Minha Conta{' '}
                </div>
              </Link>
            </li>
          </ul>

          <div className='text-center'>
            <img
              src={this.props.state.picture}
              className='img-fluid my-profile'
            />
          </div>
          <div id='p-left text-center'>
            <div className='next-title'>Dr. {this.props.state.name}</div>
            <div className='sub-title'>
              {council}/{state} {this.props.state.medical_register}
            </div>
          </div>
          <div className='my-2 my-lg-0'>
            <button
              className='btn btn-outline-success my-2 my-sm-0 botaosair'
              type='button'
              onClick={() => this.handleLogout()}
            >
              Sair
            </button>
          </div>
        </div>
      </nav>
    );
  }
}

export const NavBar = NavbarComponent;
