export const TOKEN_KEY = "@airbnb-Token";
export const USER_ID = ""
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUserId = () => localStorage.getItem(USER_ID);

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const user_id = id => {
  localStorage.setItem(USER_ID, id)
}

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
