import React, { useEffect, useState, useCallback } from 'react';

import { Container, ContainerPagination, Image } from './styles';
import { InputGroup, Form, FormControl, Button } from 'react-bootstrap';
import MaskedInput from 'react-maskedinput';
import api from '../../services/api';
import { Formik, Form as Foorm } from 'formik';
import { getUserId } from '../../services/auth';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import { format } from 'date-fns'
import arrowRight from '../../assets/images/arrow-right.png';
import arrowLeft from '../../assets/images/arrow-left.png';
import {host} from '../../services/api';

function NewAppointment(props) {
  const [patients, setPatients] = useState([]);
  const [dataPatients, setDataPatients] = useState([]);
  const [pagination, setPagination] = useState(1);

  const [doctor, setDoctor] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [textPatient, setTextPatient] = useState('');
  const [uuid, setUuid] = useState(null);
  const [birthday, setBirthday] = useState('');
  const [queixa, setQueixa] = useState(null);
  const [showPatient, setShowPatient] = useState(false);


  useEffect(() => {
    getPatients();
    getDoctorInfo();
  }, []);

  const getDoctorInfo = () => {
    api.get('/doctors/' + String(getUserId()) + '/').then((res) => {
      const data = res.data;
      setDoctor(data);
    });
  };

  const getPatients = (name = 'A') => {
    return api.get(`/patients/?ordering=name&page_size=10&name=${name}`).then(({ data }) => {
      setPatients(data);
      setDataPatients(data);
    });
  };

  const getPatientsPaginations = (url, page) => {
    console.log(url.split(host), host)
    const urlRequest = url.split(host)[1]
    console.log(urlRequest)

    return api.get(urlRequest).then(({ data }) => {
      setPatients(data);
      setDataPatients(data);
      setPagination(preState => preState + page)
    });
  };

  const filterPatient = useCallback(debounce(
    (value) => {
      if (!showPatient) setShowPatient(true);
      getPatients(value)
    }, 700)
  );


  const onSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    try {
      const date = values.data.split('/');
      await api.post('/consultations/', {
        patient: selectedPatient.id,
        doctor: doctor.id,
        date: `${date[2]}-${date[1]}-${date[0]}T${values.hora}:00`,
        consultation_duration_type: '30',
        status: 'Pending',
        consultation_type: 'Unimed',
        description: values.descricao,
      });

      setSelectedPatient('');
      setQueixa('');
      setUuid('');
      resetForm({});
      setStatus({ success: true });
      props.modalClosed();
      getDoctorInfo();
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  return (
    <Formik
      initialValues={{
        data: '',
        hora: '',
        queixaSelecionada: '',
        descricao: '',
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Foorm onSubmit={handleSubmit}>
          <Container>
            <h4 className='text-center' style={{ color: '#007469' }}>
              Nova Consulta
            </h4>
            <div className='row'>
              <div className='col'>
                <InputGroup>
                  <Form.Label>Nome do paciente</Form.Label>
                  <Form.Control
                    name='exam_requested'
                    autoComplete="off"
                    value={textPatient}
                    onFocus={() => setShowPatient(true)}
                    onBlur={debounce(() => setShowPatient(false), 500)}
                    onChange={(e) => {
                      setTextPatient(e.target.value);
                      filterPatient(e.target.value);
                    }}
                  ></Form.Control>
                </InputGroup>
                {showPatient && (
                  <div className='absoluteList'>
                    {patients?.results?.map((patient) => (
                      <Button
                        key={patient.id}
                        variant='light'
                        size='sm'
                        style={{ width: '100%', textAlign: 'left' }}
                        onClick={(e) => {
                          console.log(e)
                          const p = patients?.results.find((el) => el.id === patient.id);
                          console.log(p);
                          if (p) {
                            setSelectedPatient(p);
                            setUuid(p.uuid);
                            setTextPatient(p.name);
                            const birthdatePatient = p.birthdate.split('-')
                            setBirthday(`${birthdatePatient[2]}${birthdatePatient[1]}${birthdatePatient[0]}`);
                          }
                        }}
                      >
                        {patient.name}
                      </Button>
                    ))}

                  {patients?.results?.length > 0 && (
                    <ContainerPagination>
                      {patients?.previous && (
                        <Image src={arrowLeft} width={20} height={20} 
                          onMouseDown={e => e.preventDefault()} 
                          onClick={() => {
                            getPatientsPaginations(patients?.previous, -1);
                        }}/>
                      )}
                      
                      <span>{pagination}</span>

                      {patients?.next && (
                        <Image src={arrowRight} width={20} height={20} onMouseDown={e => e.preventDefault()} 
                         onClick={() => {
                          getPatientsPaginations(patients?.next, 1);
                        }}/>
                      )}
                    </ContainerPagination>
                  )}
                  </div>
                )}
              </div>
              <div className='col'>
                <InputGroup>
                  <Form.Label>Data de nascimento</Form.Label>
                  <MaskedInput
                    mask='11/11/1111'
                    type='text'
                    name='birthday'
                    disabled
                    className='input-modal'
                    autoComplete='off'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={birthday}
                  />
                </InputGroup>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <InputGroup>
                  <Form.Label>ID paciente</Form.Label>
                  <FormControl
                    type='text'
                    className='unico-input'
                    name='name'
                    disabled
                    value={uuid}
                    autoComplete='off'
                  />
                </InputGroup>
              </div>
            </div>
            {selectedPatient ? (
              <div className='row mt-3 mb-3'>
                <div className='col'>
                  <div className='card'>
                    <div className='card-body flex-row'>
                      <img
                        src={selectedPatient.picture}
                        alt='icone-profile'
                        className='icone-profile'
                      />
                      <div>
                        <h6>{selectedPatient.name}</h6>
                        <p className='m-0'>{selectedPatient.phonenumber}</p>
                        <p className='m-0'>Nas.: {selectedPatient.birthdate}</p>
                        <p className='m-0'>
                          Plano de saúde:{' '}
                          <span>
                            {selectedPatient.is_unimed_client ? 'Sim' : 'Não'}
                          </span>
                        </p>
                        {/* <p className='m-0'>
                    Alérgico a alguma medicação: <span>Não</span>
                  </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className='row'>
              <div className='col-xs-12 col-sm-6 col-md-6'>
                <InputGroup>
                  <Form.Label>Data da consulta</Form.Label>
                  <MaskedInput
                    mask='11/11/1111'
                    type='text'
                    name='data'
                    className='input-modal'
                    autoComplete='off'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.data}
                  />
                </InputGroup>
              </div>
              <div className='col-xs-12 col-sm-6 col-md-6'>
                <InputGroup>
                  <Form.Label>Hora da Consulta</Form.Label>
                  <MaskedInput
                    className='input-modal'
                    mask='11:11'
                    type='text'
                    name='hora'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hora}
                    autoComplete='off'
                  />
                </InputGroup>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-6 col-md-6'>
                <InputGroup>
                  <Form.Label>Paciente possui alguma queixa?</Form.Label>
                  <Form.Check
                    inline
                    type='radio'
                    name='queixa'
                    label='Sim'
                    onChange={() => {
                      setQueixa(true);
                    }}
                    onBlur={handleBlur}
                  />
                  <Form.Check
                    inline
                    type='radio'
                    name='queixa'
                    label='Não'
                    onChange={() => {
                      setQueixa(false);
                    }}
                    onBlur={handleBlur}
                    value={values.queixa}
                  />
                </InputGroup>
              </div>
              <dic className='col-xs-12 col-sm-6 col-md-6'>
                <InputGroup>
                  <Form.Label>Qual é a queixa?</Form.Label>

                  <select
                    className='input-modal'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name='queixaSelecionada'
                    value={values.queixaSelecionada}
                  >
                    <option values=''>Selecione uma queixa</option>
                    <option values='system_nervoso'>Sistema Nervoso</option>
                    <option values='system_respiratorio'>
                      Sistema Respiratório
                    </option>
                    <option values='system_cardiovascular'>
                      Sistema Cardiovascular
                    </option>
                    <option values='system_gastrointestinal'>
                      Sistema Gastrointestinal
                    </option>
                  </select>
                </InputGroup>
              </dic>
            </div>
            <div className='row'>
              <div className='col'>
                <InputGroup>
                  <Form.Label>Descreva o motivo da consulta</Form.Label>

                  <textarea
                    class='input-modal'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name='descricao'
                    value={values.descricao}
                  ></textarea>
                </InputGroup>
              </div>
            </div>
            <div className='mt-3 d-flex justify-content-between'>
              <div className=' '>
                <button
                  className='btn btn-danger'
                  onClick={props.modalClosed}
                  type='button'
                >
                  Cancelar Cosulta
                </button>
              </div>
              <div className=' '>
                <button
                  className='btn btn-success'
                  style={{ backgroundColor: '#007469' }}
                  onClick={handleSubmit}
                >
                  Confirmar Consulta
                </button>
              </div>
            </div>
          </Container>
        </Foorm>
      )}
    </Formik>
  );
}

export default NewAppointment;
