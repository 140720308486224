import styled from 'styled-components';

export const TableContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 71vh;
    overflow-y: auto;
`;

export const Table = styled.table`
  width: 95%;
  align-items: flex-start;
  box-sizing: border-box;

  tr th {
    font-size: 19px;
    padding-bottom: 1.5rem;
    padding-top: .5rem;
    padding-left: 2.75rem;
    font-weight: bold;
    color: #007469;
  }

  tr {
    border-collapse: collapse;
    line-height: 10px;
  }

  tr td {
    padding: 1rem 0;
    padding-left: 3rem;
    border-top: 2px solid #d7dadd;
    text-align: center;
    font-weight: 300;
  }

`;