import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const NotifyError = styled.div`
  align-items: center;
  justify-content: center;
`

export const ContainerInfo = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`


export const FormContent = styled.div`
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 398px;
  position: relative;
  padding: 20px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
  justify-content: center;
  align-items: center;
`;