import styled from 'styled-components';

export const Table = styled.table`
  height: 30%;
  width: 95%;
  box-sizing: border-box;

  tr {
    border-collapse: collapse;
  }

  tr td {
    padding: 1rem;
    border-top: 2px solid #d7dadd;
    border-left: 2px solid #d7dadd;
    text-align: center;
    font-weight: 300;
  }

  tr td:first-child  {
    border-left: none;
  }


`;

export const DownloadButton = styled.button`
  background: transparent;
  color: #007469;
  border: 2px solid #007469;
  border-radius: 5px;
  padding: .75rem 1rem;
  transition: 150ms;

  :hover{
    background: #007469;
    color: white;
  }
`;