import styled from 'styled-components';

export const PlanContainer = styled.div`
    width: 15rem;
    height: 20rem;
    border: 3px solid #027369;
    border-radius: 28px;
    overflow-x: hidden;
    vertical-align:top;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
`;

export const PlanHeader = styled.div`
    color: white;
    background: #027369;
    border: 2px solid #027369;
    height: 10%;
    width: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 85%;
    width: 85%;
`;

export const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: bolder;
    line-height: 2rem;
    margin-top: 2rem;
`;

export const SelectButton = styled.button`
    outline: none;
    border: none;
    text-decoration: none;
    color: white;
    background: #007469;
    font-size: 1.2rem;
    font-weight: 500;
    /* padding: .5rem 4rem; */
    height: 2.5rem;
    width: 14rem;
    border-radius: 8px;
`;