import styled from 'styled-components';

export const FormContent = styled.div`
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #fff;
  padding: 30px;
  width: 90%;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 100% !important;
  background: #fff !important;
  width: 100% !important;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.3) !important;
`;

export const H1 = styled.h1`
  font-size: 19px;
  color: #555;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
`;

export const InputComponent3 = styled.input`
  & {
    background-color: #fff;
    border: none;
    color: #0d0d0d;
    padding: 10px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 100% !important;
    border: 1px solid #ccc;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    text-align: left;
    width: 290px !important;
    padding: 8px 22px;
    font-size: 15px;
    border-radius: 0px;
  }

  :focus {
    background-color: #fff;
    border-bottom: 2px solid rgba(21, 129, 117, 255);
  }
  ::placeholder {
    color: #cccccc;
  }
`;
