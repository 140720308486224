import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import api from "../../services/api";
import jwt from 'jsonwebtoken';

import { H1, InputComponent2, SELECT } from "./styles";

import checked from "./assets/checked.svg";

import loginstyles from "../SignIn/css/login.module.css";
import styles from "./css/signup.module.css";
import { BOTAO } from "../SignIn/styles";
import NumberFormat from "react-number-format";

import InputMask from "react-input-mask";
import { Form, Col, Card, Modal, Container } from "react-bootstrap";
import MultiSelectionField from "../../components/MultiSelectionField";

class SignUp extends Component {

  constructor(props) {
    super(props);
    this.fileUpload = React.createRef();

    this.getFile = this.getFile.bind(this);
    this.changeFileName = this.changeFileName.bind(this);
  }

  state = {
    name: "",
    email: "",
    phonenumber: "",
    medical_register: "",
    council: "",
    state: "",
    category: null,
    accept_medical_agreement: false,
    medical_insurances: "",
    price: "",
    birthdate: "",
    password: "",
    password_confirmation: "",
    is_doctor: false,
    specialty: null,
    picture: "",
    error: [],
    specialties: [],
    categories: [],
    medicalInsurances: [],
    councils: [],
    states: [],
    council_states: [],
    council_state: "",
    filename: "",
    show: false,
    erros: [],
  };

  handleSignUp = async (e) => {
    e.preventDefault();

    if (!this.state.medical_insurances.length) {
      this.setState({ error: ["Selecione um convênio médico"] });
    } else if (this.state.password !== this.state.password_confirmation) {
      this.setState({ error: ["Confirme a senha corretamente."] });
    } else {
      this.setState({
        council_state: this.findCouncilState(
          this.state.council_states,
          this.state.council,
          this.state.state
        ),
      });
    
      let tokenData = {};
      try {
        tokenData = jwt.verify(this.props.match.params.token, "telemedfront");
        console.log(tokenData);
      } catch (err) {
        console.log(err);
      }

      let data = new FormData();

      data.append("password", this.state.password);
      data.append("password_confirmation", this.state.password_confirmation);
      data.append("is_doctor", this.state.is_doctor);
      data.append("genre", "M");
      data.append("price", this.state.price.replace(/\D/g, ""));
      data.append("birthdate", this.state.birthdate);
      data.append("council", this.state.council);
      data.append("medical_register", this.state.medical_register);
      data.append("category", this.state.category);
      data.append("specialty", [this.state.specialty]);
      data.append("accept_medical_agreement", this.state.accept_medical_agreement);
      data.append("state", this.state.state);
      this.state.medical_insurances.map((e) => e.key).forEach((item) => {
        data.append('medical_insurances', item);
        console.log(item);
      });
      data.append("picture", this.state.picture);
      data.append("doctor", tokenData.id);
      data.append("authorization_code", tokenData.authorization);

      api
        .put("/doctors/finish_register/", data)
        .then(() => this.setState({ show: true }))
        .catch((error) => {
          this.setState({
            error: error.response.data?.password,
          });
        });

    }
  };

  componentDidMount() {
    this.getSpecialties();
    this.getCategories();
    this.getInsurances();
    this.getCouncils();
    this.getStates();
    this.getCouncilStates();
  }

  getSpecialties() {
    let initialSpecialties = [];
    api.get("/specialties/").then((res) => {
      const data = res.data;
      initialSpecialties = data.map((specialty) => {
        return specialty;
      });
      console.log(initialSpecialties);
      this.setState({
        specialties: initialSpecialties,
        specialty: initialSpecialties[0].id,
      });
    });
  }

  getInsurances() {
    let initialInsurances = [];
    api.get("/medical_insurances/").then((res) => {
      const data = res.data;
      initialInsurances = data.map((specialty) => {
        return specialty;
      });
      this.setState(
        {
          medicalInsurances: initialInsurances,
          medical_insurances: [],
        },
        () => console.log(initialInsurances)
      );
    });
  }

  getStates() {
    let initialInsurances = [];
    api.get("/states/").then((res) => {
      const data = res.data;
      initialInsurances = data.map((specialty) => {
        return specialty;
      });
      this.setState({
        states: initialInsurances,
        state: initialInsurances[0].id,
      });
    });
  }

  getCouncils() {
    let initialInsurances = [];
    api.get("/councils/").then((res) => {
      const data = res.data;
      initialInsurances = data.map((specialty) => {
        return specialty;
      });
      this.setState({
        councils: initialInsurances,
        council: initialInsurances[0].id,
      });
    });
  }

  findCouncilState(councilstates, council, state) {
    for (var i = 0; i < councilstates.length; i++) {
      if (
        councilstates[i].state.id === state &&
        councilstates[i].council.id === council
      )
        return councilstates[i].id;
    }
  }

  getCouncilStates() {
    let initialInsurances = [];
    api.get("/council_states/").then((res) => {
      const data = res.data;
      initialInsurances = data.map((specialty) => {
        return specialty;
      });
      this.setState({
        council_states: initialInsurances,
        council_state: this.findCouncilState(
          initialInsurances,
          this.state.state,
          this.state.council
        ),
      });
    });
  }

  getCategories() {
    let initialCategories = [];
    api.get("/medical_categories/").then((res) => {
      const data = res.data;
      initialCategories = data.map((category) => {
        return category;
      });
      console.log(initialCategories);
      this.setState({
        categories: initialCategories,
        category: initialCategories[0].id,
      });
    });
  }

  rebuildDate(param, to) {
    if (param.length == 0) return param;
    if (to == 1) {
      let vari = param.split("-");
      let varano = vari[0];
      let varmes = vari[1];
      let vardia = vari[2];
      return `${vardia}/${varmes}/${varano}`;
    } else {
      let vari = param.split("/");
      return `${vari[2]}-${vari[1]}-${vari[0]}`;
    }
  }

  getFile() {
    this.fileUpload.current.click();
  }

  changeFileName() {
    this.setState({
      filename: this.fileUpload.current.files[0].name,
      picture: this.fileUpload.current.files[0],
    });
    console.log(this.fileUpload.current.files);
  }

  render() {
    let specialties = this.state.specialties;
    let optionItems = specialties.map((specialty) => (
      <option value={specialty.id} key={specialty.id}>
        {specialty.name}
      </option>
    ));
    let categories = this.state.categories;
    let optionCategories = categories.map((category) => (
      <option value={category.id} key={category.id}>
        {category.name}
      </option>
    ));
    let medicalInsurances = this.state.medicalInsurances;
    let optionMedicalInsurances = medicalInsurances.map((insurance) => (
      {
        value: insurance.id,
        label: insurance.name,
        key: insurance.id,
      }
    ));


    let states = this.state.states;
    let optionstates = states.map((state) => (
      <option value={state.id} key={state.id}>
        {state.sigla}
      </option>
    ));

    let councils = this.state.councils;
    let optioncouncils = councils.map((council) => (
      <option value={council.id} key={council.id}>
        {council.sigla}
      </option>
    ));

    return (
      <div style={{ background: "#007469", height: "100vh" }}>
        <div className={loginstyles.wrapper + " " + loginstyles.fadeInDown}>
          {/* <FormContent> */}
          <div className="content">
            <Container>
              <Col className="ml-auto mr-auto" md="13">
                <Card className="card-login card-plain">
                  <Form onSubmit={this.handleSignUp}>
                    <div className={styles.myspace}>
                      <Link
                        className={
                          loginstyles.link + " " + loginstyles.underlineHover
                        }
                        to="signin"
                        id={loginstyles.bbox}
                      >
                        <div className="fa fa-long-arrow-left"></div>
                        &nbsp;Voltar
                      </Link>
                      <H1>Cadastre-se</H1>
                      <p id={styles.person}>
                        insira as informações solicitadas abaixo
                      </p>
                      <p id={styles.person} className="d-flex flex-column">
                        {this.state.error?.map((err, i) => (
                          <font key={i} color="red">
                            {err}
                          </font>
                        ))}
                      </p>

                      <Form.Row>
                        <div
                          className={
                            "col-xs-12 col-sm-6 col-md-6 " + styles.zero_left
                          }
                        >
                          <div className="form-group">
                            <label style={{ fontSize: "12px" }}>
                              Tipo profissional
                            </label>
                            <SELECT
                              className={
                                loginstyles.fadein +
                                " " +
                                loginstyles.second +
                                " " +
                                styles.newtype
                              }
                              onChange={(e) =>
                                this.setState({ category: e.target.value })
                              }
                              required
                            >
                              {optionCategories}
                            </SELECT>
                          </div>
                        </div>

                        <div
                          className={
                            "col-xs-12 col-sm-6 col-md-6 " + styles.zero_right
                          }
                        >
                          <div className="form-group">
                            <label style={{ fontSize: "12px" }}>
                              Informe sua Especialidade{" "}
                            </label>
                            <SELECT
                              className={
                                loginstyles.fadein +
                                " " +
                                loginstyles.second +
                                " " +
                                styles.newtype
                              }
                              onChange={(e) =>
                                this.setState({ specialty: Number(e.target.value) })
                              }
                            >
                              {optionItems}
                            </SELECT>
                          </div>
                        </div>
                      </Form.Row>

                      <Form.Row>
                        <div
                          className={
                            "col-xs-12 col-sm-6 col-md-6 " + styles.zero_left
                          }
                        >
                          <div className="form-group">
                            <label style={{ fontSize: "12px" }}>
                              Aceita Convênio Médico?
                            </label>
                            <div className={styles.exemplo1}>
                              <input
                                type="radio"
                                value={true}
                                name="campo-radio"
                                id="campo-radio1"
                                checked
                                onChange={(e) =>
                                  this.setState({
                                    accept_medical_agreement: e.target.value,
                                  })
                                }
                              />
                              <label
                                className={styles.add}
                                htmlFor="campo-radio1"
                                style={{ fontSize: "12px" }}
                              >
                                Sim
                              </label>
                              <input
                                type="radio"
                                value={false}
                                name="campo-radio"
                                id="campo-radio2"
                                onChange={(e) =>
                                  this.setState({
                                    accept_medical_agreement: e.target.value,
                                  })
                                }
                              />
                              <label
                                className={styles.add}
                                htmlFor="campo-radio2"
                                style={{ fontSize: "12px" }}
                              >
                                Não
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            "col-xs-12 col-sm-6 col-md-6 " + styles.zero_right
                          }
                        >
                          <div className="form-group" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "column" }}>
                            <label style={{ fontSize: "12px" }}>
                              Convênio Médico Aceito
                            </label>
                            <MultiSelectionField
                              selectOptions={optionMedicalInsurances}
                              optionSelected={this.state.medical_insurances}
                              setOptionSelected={(e) => {
                                this.setState({
                                  medical_insurances: e,
                                })
                              }}
                            />
                          </div>
                        </div>
                      </Form.Row>

                      <Form.Row>
                        <div
                          className={
                            "col-xs-12 col-sm-6 col-md-6 " + styles.zero_left
                          }
                        >
                          <div className="form-group">
                            <label style={{ fontSize: "12px" }}>
                              Valor da Consulta particular
                            </label>
                            <NumberFormat
                              thousandSeparator={false}
                              decimalSeparator={"."}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={"R$"}
                              className={styles.myinputcomponent}
                              placeholder="Valores maiores que 0,00 R$"
                              value={this.state.price}
                              required
                              onValueChange={(values) => {
                                const { formattedValue, value } = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                this.setState({ price: formattedValue });
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            "col-xs-12 col-sm-6 col-md-6 " + styles.zero_right
                          }
                        >
                          <div className="form-group">
                            <label style={{ fontSize: "12px" }}>
                              Data de Nascimento
                            </label>
                            <InputMask
                              type="text"
                              mask="99/99/9999"
                              className={
                                styles.myinputcomponent +
                                " " +
                                loginstyles.fadein +
                                " " +
                                loginstyles.second
                              }
                              name="data"
                              value={this.rebuildDate(this.state.birthdate, 1)}
                              placeholder="00/00/0000"
                              onChange={(e) =>
                                this.setState({
                                  birthdate: this.rebuildDate(
                                    e.target.value,
                                    2
                                  ),
                                })
                              }
                            />
                          </div>
                        </div>
                      </Form.Row>

                      <Form.Row>
                        <div
                          className={
                            "col-xs-12 col-sm-6 col-md-6 " + styles.zero_right
                          }
                          id={styles.rage}
                        >
                          <div className="form-group">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <label
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                Conselho
                              </label>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: "no",
                              }}
                            >
                              <InputComponent2
                                type="tel"
                                id={styles.register}
                                className={
                                  loginstyles.fadein +
                                  " " +
                                  loginstyles.second +
                                  " "
                                }
                                name="medical_register"
                                value={this.state.medical_register}
                                placeholder="Nº de registro"
                                onChange={(e) =>
                                  this.setState({
                                    medical_register: e.target.value,
                                  })
                                }
                                required
                                style={{ display: "flex" }}
                              />
                              <SELECT
                                className={
                                  loginstyles.fadein +
                                  " " +
                                  loginstyles.second +
                                  " " +
                                  styles.newtype
                                }
                                id={styles.type}
                                onChange={(e) =>
                                  this.setState({ council: e.target.value })
                                }
                                required
                                style={{ fontSize: "12px" }}
                              >
                                {optioncouncils}
                              </SELECT>
                              <SELECT
                                className={
                                  loginstyles.fadein +
                                  " " +
                                  loginstyles.second +
                                  " " +
                                  styles.newtype
                                }
                                id={styles.state}
                                onChange={(e) =>
                                  this.setState({ state: e.target.value })
                                }
                                style={{ fontSize: "12px" }}
                              >
                                {optionstates}
                              </SELECT>
                            </div>
                          </div>
                        </div>
                      </Form.Row>

                      <Form.Row style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Form.Check
                          type="checkbox"
                          name="q1_myOptions[]"
                          // id="input_1_0"
                          onChange={(e) =>
                            this.setState({
                              is_doctor: e.target.checked,
                            })
                          }
                          label="Não sou Médico"
                          style={{ fontSize: "12px" }}
                        />
                      </Form.Row>
                    </div>

                    <div id={styles.color_gray}>
                      <div className={styles.myspace}>
                        <Form.Row>
                          <div
                            className={
                              "col-xs-12 col-sm-6 col-md-6 " + styles.zero_left
                            }
                          >
                            <div className="form-group">
                              <label style={{ fontSize: "12px" }}>Senha</label>
                              <i>
                                <InputComponent2
                                  type="password"
                                  id="password"
                                  className={
                                    loginstyles.fadein +
                                    " " +
                                    loginstyles.second
                                  }
                                  name="password"
                                  placeholder="Letras e Números"
                                  required
                                  value={this.state.password}
                                  onChange={(e) =>
                                    this.setState({ password: e.target.value })
                                  }
                                />
                              </i>
                            </div>
                          </div>
                          <div
                            className={
                              "col-xs-12 col-sm-6 col-md-6 " + styles.zero_right
                            }
                          >
                            <div className="form-group">
                              <label style={{ fontSize: "12px" }}>
                                Confirmar Senha:
                              </label>
                              <i>
                                <InputComponent2
                                  type="password"
                                  id="confirm-password"
                                  className={
                                    loginstyles.fadein +
                                    " " +
                                    loginstyles.second
                                  }
                                  placeholder="Letras e Números"
                                  name="confirm-password"
                                  value={this.state.password_confirmation}
                                  onChange={(e) =>
                                    this.setState({
                                      password_confirmation: e.target.value,
                                    })
                                  }
                                  required
                                />
                              </i>
                            </div>
                          </div>
                        </Form.Row>

                        <Form.Row>
                          <div
                            className={
                              "col-xs-12 col-sm-6 col-md-6 " + styles.zero_left
                            }
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "center",
                            }}
                          >
                            <div className="form-group">
                              <label
                                className={styles.linecenter}
                                style={{ fontSize: "12px" }}
                              >
                                Upload de Foto
                              </label>
                              <input
                                type="file"
                                name="arquivo"
                                id="arquivo"
                                className={styles.arquivo}
                                ref={this.fileUpload}
                                onChange={this.changeFileName}
                                required
                                style={{ fontSize: "12px" }}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              "col-xs-12 col-sm-6 col-md-6 " + styles.zero_left
                            }
                          >
                            <div className="form-group">
                              <BOTAO
                                type="submit"
                                className={
                                  loginstyles.fadeIn +
                                  " " +
                                  loginstyles.fourth +
                                  " " +
                                  styles.testebotao
                                }
                                value="Enviar"
                                id={styles.register_button}
                              />
                            </div>
                          </div>
                        </Form.Row>
                      </div>
                    </div>
                  </Form>
                </Card>
              </Col>
            </Container>
          </div>
          <Modal
            show={this.state.show}
            onHide={() => {
              this.setState({ show: false });
              this.props.history.push("/signin");
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <img
                  src={checked}
                  alt="checked"
                  style={{ width: "55px", height: "55px" }}
                />
                <span
                  style={{
                    fontSize: "22px",
                    color: "#168175",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  Cadastro realizado com sucesso!
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    color: "#B0B0B9",
                    textAlign: "center",
                  }}
                >
                  Estamos validando as informações enviadas <br /> e vamos
                  liberar seu acesso a plataforma <br /> o mais breve possível.
                </span>
                <span
                  style={{
                    fontSize: "13px",
                    color: "#168175",
                    marginTop: "20px",
                  }}
                >
                  Todas as informações serão enviadas para o e-mail abaixo
                </span>
                <span
                  style={{
                    fontSize: "13px",
                    marginTop: "5px",
                  }}
                >
                  {this.state.email}
                </span>
                <button
                  style={{
                    fontSize: "13px",
                    backgroundColor: "#fff",
                    marginTop: "15px",
                    border: "1px",
                    borderColor: "#168175",
                    color: "#168175",
                    borderStyle: "solid",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    this.setState({ show: false });
                    this.props.history.push("/signin");
                  }}
                >
                  ok
                </button>
              </div>
            </Modal.Body>
          </Modal>
          {/* </FormContent> */}
        </div>
      </div>
    );
  }
}

export default withRouter(SignUp);
