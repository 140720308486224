import React, { useState } from 'react';
import { format } from 'date-fns';
import ModalComponent from '../../pages/ModalComponent';
import SingleCalendar from '../SingleCalendar';
import '../../pages/HomeDashboard/dashboard.css';
import {
    FilterRow,
    FilterContainer,
    HomeFilterContainer,
    HomeFilter,
    CalendarContainer
} from './style';

import {
    CloseButtonContainer,
    CloseButton,
} from '../../pages/ReportsPage/style';

import icon_lupa from '../../assets/images/icons/lupa.svg'
import icon_calendar from '../../assets/images/icons/calendario.svg'

export default function PageHeader(
    { state,
        pageTitle,
        pageDescription,
        nameFilter,
        setNameFilter,
        dateFilter,
        setDateFilter,
        disabled
    }) {
    const [openCalendar, setOpenCalendar] = useState(false);


    return (
        <>
            <div style={{ marginLeft: '5.25rem' }}>
                <FilterRow>
                    <ModalComponent doctor={state} />

                    <FilterContainer>
                        <HomeFilterContainer>
                            <HomeFilter
                                placeholder='Nome do Paciente'
                                value={nameFilter}
                                onChange={(e) => setNameFilter(e.target.value)}
                                disabled={disabled}
                            />
                            <img src={icon_lupa} />
                        </HomeFilterContainer>

                        <HomeFilterContainer>
                            <button disabled={disabled} style={{ border: 'none', background: 'none', outline: 'none', padding: '0', cursor: 'pointer' }} onClick={() => setOpenCalendar(true)}>
                                <HomeFilter
                                    placeholder='Pesquisar por Data'
                                    value={(typeof dateFilter!=='undefined' && dateFilter) ?
                                        `${dateFilter.getDate() + "/" + format(dateFilter, 'LL') + "/" + dateFilter.getFullYear()}`
                                        : null}
                                    disabled={true}
                                />
                            </button>
                            <img src={icon_calendar} />
                        </HomeFilterContainer>
                        {
                            openCalendar ? (
                                <CalendarContainer>
                                    <CloseButtonContainer>
                                        <CloseButton style={{ outline: 'none' }} onClick={() => setOpenCalendar(false)}>x</CloseButton>
                                    </CloseButtonContainer>

                                    <SingleCalendar
                                        selectedDate={(typeof dateFilter!=='undefined' && dateFilter) ? dateFilter : new Date()}
                                        setSelectedDate={setDateFilter}
                                    />
                                </CalendarContainer>
                            ) : null
                        }
                    </FilterContainer>

                </FilterRow>

                <h1 className='myh1'>{pageTitle}</h1>
                <p id='subtext'>
                    {pageDescription}
                </p>
            </div><hr className='myhr'></hr>
        </>
    );
}