import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import './newsidebar.css';
import Modal from '../../components/Modal';
import NewAppointment from '../../components/NewAppointment';
import { Button } from 'react-bootstrap';

import icon_consult from '../../assets/images/icons/criar_consulta.svg'
import icon_initial from '../../assets/images/icons/inicial.svg'
import icon_history from '../../assets/images/icons/historico.svg'
import icon_profile from '../../assets/images/icons/minha_conta.svg'
import icon_report from '../../assets/images/icons/relatorios.svg'

class NewSideBar extends Component {
  state = {
    openNewAppointmentModal: false,
    linkModal: false,
    input:
      'https://play.google.com/store/apps/details?id=br.com.telemedicina.arquimed',
  };

  toggleAppointmentModal = () =>
    this.setState({
      openNewAppointmentModal: !this.state.openNewAppointmentModal,
    });

  toggleLinkModal = () =>
    this.setState({
      linkModal: !this.state.linkModal,
    });

  render() {
    return (
      <>
        <nav id='menu-lateral' style={{ top: this.props.top }}>
          <ul id='sub-menu'>
            <Link to='/' style={{ color: 'white', textDecoration: 'none' }}>
              <li className='nli'>
                <img src={icon_initial} style={{ width: '2em' }} />
                <span className='nomeicon'>Inicial</span>
              </li>
            </Link>
            <div
              onClick={() => this.toggleLinkModal()}
              to='/create-consult'
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <li className='nli'>
                <img src={icon_consult} style={{ width: '2em' }} />
                <span className='nomeicon'>Criar Consulta</span>
              </li>
            </div>
            <Link
              to='/history'
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <li className='nli'>
                <img src={icon_history} style={{ width: '2em' }} />

                <span className='nomeicon'>Histórico</span>
              </li>
            </Link>
            <Link
              to='/editprofile'
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <li className='nli'>
                <img src={icon_profile} style={{ width: '2em' }} />
                <span className='nomeicon'>Minha Conta</span>
              </li>
            </Link>
            <Link to='/reports' style={{ color: 'white', textDecoration: 'none' }}>
              <li className='nli'>
                <img src={icon_report} style={{ width: '2em' }} />
                <span className='nomeicon'>Relatórios</span>
              </li>
            </Link>
          </ul>
        </nav>
        <Modal
          show={this.state.openNewAppointmentModal}
          modalClosed={this.toggleAppointmentModal}
        >
          <NewAppointment
            // getDoctorInfo={this.props.getDoctorInfo}
            modalClosed={this.toggleAppointmentModal}
            toggleLinkModal={this.toggleLinkModal}
          />
        </Modal>

        <Modal show={this.state.linkModal} modalClosed={this.toggleLinkModal}>
          <div className='madal'>
            <p>
              Copie o link abaixo e envie para seu <br /> paciente se cadastrar
              no sistema.
            </p>

            <input
              type='text'
              value={this.state.input}
              className='input-modal-link'
              autoFocus
            />

            <Button
              className='madal-btn'
              onClick={() => {
                this.toggleLinkModal();
                this.toggleAppointmentModal();
              }}
            >
              Cadastrar
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default NewSideBar;
