import React from 'react';
import api from '../../services/api';
import { Table, DownloadButton } from './styles';
import { formatISO } from 'date-fns';

export default function ReportTable({
    items,
    doctor,
    specialty,
    firstDate,
    lastDate,
}) {

    const downloadReport = async (patient_id) => {
        try {
            console.log(patient_id);
            const res = await api.get(`/consultations/pdf_report/?date_after=${formatISO(firstDate).slice(0, 10)}&date_before=${formatISO(lastDate).slice(0, 10)}&patient=${patient_id}`, {
                responseType: 'arraybuffer',
              });
            console.log(res.data);

            const blob = new Blob([res.data], {
                type: res.headers["content-type"],
            });
            

            let objectURL = URL.createObjectURL(blob);
            window.open(objectURL);

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Table cellSpacing={0} cellPadding={0}>
            <tbody>
                <tr>
                    <td>
                        Nome do Paciente
                    </td>
                    <td>
                        Convênio
                    </td>
                    <td>
                        Matrícula
                    </td>
                    <td>
                        Profissional
                    </td>
                    <td>
                        Especialidade
                    </td>
                    <td>
                        Download
                    </td>
                </tr>

                {items.map((row, index) => (
                    <tr key={index}>
                        <td>
                            {row.patient.name}
                        </td>
                        <td>
                            {row.patient.is_unimed_client ? "Unimed" : " "}
                        </td>
                        <td>
                            {row.patient.unimed_register_number}
                        </td>
                        <td>
                            {doctor}
                        </td>
                        <td>
                            {specialty}
                        </td>
                        <td>
                            <DownloadButton
                                style={{ outline: 'none' }}
                                onClick={() => {
                                    downloadReport(row.patient.id);
                                }}
                            >
                                Download
                            </DownloadButton>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}