import React from "react";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import PropTypes from "prop-types";
import {
    SelectComponent,
    SelectOption
} from './style';

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <SelectOption>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </SelectOption>
            </components.Option>
        </div>
    );
};

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);


const MySelect = props => {
    if (props.allowSelectAll) {
        return (
            <SelectComponent
                {...props}
                options={[props.allOption, ...props.options]}
                onChange={selected => {
                    if (
                        selected !== null &&
                        selected.length > 0 &&
                        selected[selected.length - 1].value === props.allOption.value
                    ) {
                        return props.onChange(props.options);
                    }
                    return props.onChange(selected);
                }}
            />
        );
    }

    return <SelectComponent {...props} />;
};

MySelect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

MySelect.defaultProps = {
    allOption: {
        label: "Selecionar todos",
        value: "*"
    }
};

const animatedComponents = makeAnimated();

export default function MultiSelectionField({ selectOptions, optionSelected, setOptionSelected }) {

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : undefined,
            backgroundColor: state.isSelected
                        ? "#00746950"
                        : state.isFocused
                            ? "#00746925"
                            : undefined,
            padding: 5,
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "350px",
            maxWidth: "500px",
            height: "40px",
            overflow: "hidden",
            border: "1px solid #cccccc",
            borderRadius: "5px",
            fontSize: "12px"
        }),
        multiValue: () => ({
            color: "#007469",
            backgroundColor: "#00746950",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginInline: "2px",
        }),
    }

    return (

        <MySelect
            styles={customStyles}
            options={selectOptions}
            isMulti
            defaultValue={selectOptions[1]}
            isClearable={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={setOptionSelected}
            allowSelectAll={true}
            value={optionSelected}
        />
    );
}