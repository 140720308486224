import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import Logo from "../../assets/logo_white.png";
import api from "../../services/api";
import { login, user_id } from "../../services/auth";

import { Container, SPAN2, FormContent, FormLogin, FormFooter, LABEL, SPAN, H1, InputComponent, BOTAO, Info } from "./styles";

import styles from "./css/login.module.css"


class SignIn extends Component {
  state = {
    email: "",
    password: "",
    error: ""
  };

  handleSignIn = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("/api-token-auth/", { "email": email, "password": password });
        login(response.data.token);
        user_id(response.data.user.id);
        this.props.history.push("/app");
      } catch (err) {
        console.log(err)
        this.setState({
          error:
            "Houve um problema com o login, verifique suas credenciais. T.T"
        });
      }
    }
  };

  render() {
    return (
      <Container style={{ "background-color": "#007469" }}>
        <div className={styles.wrapper + " " + styles.fadeInDown} >
          <div className={styles.fadeIn + " " + styles.first}>
            <img src={Logo} id={styles.icon} alt="Logo" />
          </div>
          <div className="row">&nbsp;</div>
          <FormContent>
            <FormLogin onSubmit={this.handleSignIn}>
              <H1>Login</H1>
              <Info>Faça login para acessar o sistema!</Info>
              {this.state.error && <p><font color="red">{this.state.error}</font></p>}
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <LABEL>E-mail</LABEL>
              </div>
              <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: '85%', display: 'flex', alignItems: 'center'}}>
                  <InputComponent
                    type="email"
                    id="login"
                    className={styles.fadeIn + " " + styles.third}
                    onChange={e => this.setState({ email: e.target.value })}
                    name="login"
                    placeholder="seuemail@email.com"
                    required=""
                    style={{fontFamily: 'Lato', color: '#aaa'}}
                  />
                  <div style={{position: 'absolute', marginLeft: 15}}>
                    <SPAN/>
                  </div>
                </div>
              </div>

              <div style={{display: 'flex', justifyContent: 'center'}}>
                <LABEL>Senha</LABEL>
              </div>
              <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: '85%', display: 'flex', alignItems: 'center'}}>
                  <InputComponent
                    id="textinput"
                    type="password"
                    className={styles.fadeIn + " " + styles.third}
                    placeholder="********"
                    required=""
                    onChange={e => this.setState({ password: e.target.value })}
                    style={{fontFamily: 'Lato', color: '#aaa'}}
                  />
                  <div style={{position: 'absolute', marginLeft: 15}}>
                    <SPAN2/>
                  </div>
                </div>
              </div>

              <div style={{width: '100%', marginTop: 7, marginBottom: 7}}>
                <Link 
                  className={styles.underlineHover + " " + styles.myright}
                  to="forgot_password"
                  style={{fontSize: 15}}>
                    Esqueceu a senha?
                </Link>
              </div>

              <div class={{width: '100%'}}>
                <BOTAO type="submit" className={styles.fadeIn + " " + styles.fourth} value="Entrar" />
              </div>

            </FormLogin>

            <FormFooter>
              <Link 
                className={styles.link + " " + styles.underlineHover}
                to="payment"
                style={{fontFamily: 'Lato'}}
                >
                  Não possui conta? <u>Cadastre-se</u>
              </Link>
            </FormFooter>

          </FormContent>
        </div>
      </Container>
    );
  }
}

export default withRouter(SignIn);