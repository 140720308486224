import React from "react";
import Routes from "./routes";
import { ToastContainer } from 'react-toastify';
import GlobalStyle from "./styles/global";


const App = () => {
  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <Routes />
    </>
  );
}
export default App;