import React from 'react';
import { format, isSameDay, isSameMonth, addMonths, subMonths, formatISO } from 'date-fns'
import { takeMonth } from '../../modules/calendar';
import {
    SingleContainer,
    MonthContainer,
    DatesContainer,
    DateCell,
    MonthRow,
    ChangeMonthButton,
} from './style';

function WeekNames() {
    return <tr>
        {
            ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'].map(
                (dayName) => (
                    <td>
                        {dayName}
                    </td>
                ),
            )
        }
    </tr>
}


export default function SingleCalendar({selectedDate, setSelectedDate}) {

    // const [selectedDate, setSelectedDate] = useState(new Date());

    const monthDic = {
        January: 'Janeiro',
        February: 'Fevereiro',
        March: 'Março',
        April: 'Abril',
        May: 'Maio',
        June: 'Junho',
        July: 'Julho',
        August: 'Agosto',
        September: 'Setembro',
        October: 'Outubro',
        November: 'Novembro',
        December: 'Dezembro',
    };

    function MonthlyCalendar1() {
        const data = takeMonth(selectedDate)();

        function dayStyle(day) {
            //selected date
            if (isSameDay(day, selectedDate)) return { color: 'white', background: '#007469', cursor: 'pointer' };

            return { cursor: 'pointer' };
        }

        return (
            <SingleContainer>
                <h1 style={{ fontSize: '.7rem', fontWeight: '700', color: '#007469' }}>{format(selectedDate, 'yyyy')}</h1>

                <MonthRow>
                    <ChangeMonthButton
                        onClick={() => {
                            setSelectedDate(subMonths(selectedDate, 1))
                        }}
                    >
                        {"<"}
                    </ChangeMonthButton>
                    <h1 style={{ fontSize: '1rem', fontWeight: '700' }}>{monthDic[format(selectedDate, 'MMMM')]}</h1>
                    <ChangeMonthButton
                        onClick={() => {
                            setSelectedDate(addMonths(selectedDate, 1))
                        }}
                    >
                        {">"}
                    </ChangeMonthButton>
                </MonthRow>
                <DatesContainer cellSpacing={0}>
                    <WeekNames />
                    {data.map((week) => (
                        <tr>
                            {week.map(
                                (day) => {
                                    return (
                                        <DateCell
                                            onClick={() => {
                                                setSelectedDate(day)
                                                console.log(formatISO(day).slice(0,10))
                                            }}
                                            style={dayStyle(day)}
                                        >
                                            {
                                                isSameMonth(day, selectedDate) ? (format(day, 'dd')) : (" ")
                                            }
                                        </DateCell>
                                    )
                                }

                            )
                            }
                        </tr>
                    )
                    )
                    }
                </DatesContainer>
            </SingleContainer>
        );
    }

    return (
        <MonthContainer>
            <MonthlyCalendar1 />
        </MonthContainer>
    );
}