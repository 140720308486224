import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import {
    SelectButton,
    PlanContainer,
    PlanHeader,
    InfoContainer,
    PriceContainer,
} from './style';
import LoadingState from '../../components/LoadingState';


export default function SubscriptionPlan({
    id,
    description,
    interval,
    selectFunction,
}) {
    const [price, setPrice] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const periodDic = {
        YEAR: "Anual",
        MONTH: "Mensal",
    }

    const intervalDic = {
        YEAR: "Ao ano",
        MONTH: "Ao mês",
    }

    const getPlanInstallments = async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`/doctor_plans/${id}/installments/`);
            setPrice(res.data[0].installment_value);

        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if(!price) getPlanInstallments();
    })

    return (
        <PlanContainer>
            <PlanHeader>
                {periodDic[interval]}
            </PlanHeader>

            <InfoContainer>

                {
                    isLoading ? (
                        <LoadingState />
                    ) : (

                        <>
                            <PriceContainer>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                    <div style={{ fontSize: "1.5rem" }}>R$</div>
                                    <div style={{ fontSize: "3rem" }}>{price.slice(0, -3)}</div>
                                    <div style={{ fontSize: "2rem" }}>,{price.slice(-2)}</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>{intervalDic[interval]}</div>
                            </PriceContainer>
                            <div style={{ fontSize: ".75rem", fontWeight: "500", textAlign: "center" }}>
                                {description}
                            </div>
                            <SelectButton
                                style={{ width: "12rem", outline: "none" }}
                                onClick={selectFunction}
                            >
                                Selecionar
                            </SelectButton>
                        </>
                    )
                }
            </InfoContainer>

        </PlanContainer>
    );
};