import React, { useState, useEffect, useRef } from 'react';
import { getUserId } from '../../services/auth';
import { NavBar } from '../Navbar';
import PageHeader from '../../components/PageHeader';
import LoadingState from '../../components/LoadingState';
import api from '../../services/api';
import NewSideBar from '../NewSideBar';
import './dashboard.css';
import ConsultasPendentes from './ConsultasPendentes';
import { ContainerPagination, Image } from './style';
import arrowRight from '../../assets/images/arrow-right.png';
import arrowLeft from '../../assets/images/arrow-left.png';
import { host } from '../../services/api';
import ConsultList from '../../components/ConsultList';

function HomeDashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [nameFilter, setNameFilter] = useState('');
  const [dateFilter, setDateFilter] = useState();
  const [currentConsult, setCurrentConsult] = useState({});
  const myRef = useRef(null);

  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phonenumber: '',
    is_doctor: false,
    council: {},
    medical_register: '',
    consultations: {
      results: []
    },
    pagination: 1,
    doctor_agenda: [],
    category: null,
    specialty: null,
    accept_medical_agreement: true,
    medical_insurances: '',
    picture: '',
    error: '',
    price: 0,
    specialties: [],
    categories: [],
    medicalInsurances: [],
    councils: [],
    states: [],
    council_states: [],
    state: '',
    council: '',
    council_state: '',
    filename: '',
    picture: null,
    filterDay: 0,
  });

  const getDoctorInfo = async () => {
    setIsLoading(true);
    await api.get('/doctors/' + String(getUserId()) + '/').then((res) => {
      const data = res.data;
      setState({
        name: data.name,
        email: data.email,
        phonenumber: data.phonenumber,
        category: { id: data.category.id, name: data.category.name },
        specialty: data.specialty,
        accept_medical_agreement: data.accept_medical_agreement,
        medical_insurances: data.medical_insurances,
        picture: data.picture,
        council: data.council,
        medical_register: data.medical_register,
        consultations: {
          results: data.doctor_consultations
        },
        saveConsultations: data.doctor_consultations,
        price: data.price,
        doctor_agenda: data.doctor_agenda,
      });
    });
    setIsLoading(false);
  };

  const getConsultations = async () => {
    setIsLoading(true);
    await api.get('/consultations/?page_size=20').then((res) => {
      const data = res.data;
      console.log(data);
      setState({
        consultations: data,
      });
    });
    setIsLoading(false);
  };

  const getConsultationsPagination = async (url, page) => {
    setIsLoading(true);
    const urlRequest = url.split(host)[1]
    await api.get(urlRequest).then((res) => {
      const data = res.data;
      console.log(data);
      setState({
        consultations: data,
        pagination: state.pagination + page,
      });
    });
    setIsLoading(false);
  };

  function setCurrentConsultation(param) {
    setCurrentConsult(param);
    myRef.current.scrollIntoView();
  }

  useEffect(() => {
    getDoctorInfo();
    getConsultations();
  }, []);

  const { consultations, pagination } = state;

  return (
    <div className='mydiv'>
      <NavBar state={state}></NavBar>
      <div className='newSideContext'>
        <NewSideBar getDoctorInfo={getDoctorInfo}></NewSideBar>
        <div className='mycontent'>
          <PageHeader
            state={state}
            pageTitle='Consultas Agendadas'
            pageDescription='Pacientes que solicitaram o agendamento com você'
            nameFilter={nameFilter}
            setNameFilter={setNameFilter}

            dateFilter={typeof dateFilter !== 'undefined' ? dateFilter : null}
            setDateFilter={setDateFilter}
          />

          {
            !isLoading ? (
              <>
                <ConsultList
                  nameFilter={nameFilter}
                  dateFilter={dateFilter}
                  consultations={consultations.results}
                  setCons={setCurrentConsultation}
                />

                {consultations?.results?.length > 0 ? (
                  <ContainerPagination>
                    {consultations?.previous && (
                      <Image src={arrowLeft} width={30} height={30} onClick={() => {
                        getConsultationsPagination(consultations?.previous, -1);
                      }} />
                    )}
                    <span>{pagination}</span>
                    {consultations?.next && (
                      <Image src={arrowRight} width={30} height={30} onClick={() => {
                        getConsultationsPagination(consultations?.next, 1);
                      }} />
                    )}
                  </ContainerPagination>
                ) : null}


                <ConsultasPendentes
                  refe={myRef}
                  doctor={state}
                  consultation={currentConsult}
                  getDoctorInfo={getDoctorInfo}
                  setcons={setCurrentConsultation}
                />


              </>
            ) : (
              <LoadingState />
            )
          }

        </div>
      </div>
    </div>
  );
}

export default HomeDashboard;
