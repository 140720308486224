import React, { Component, Fragment } from 'react';
import api from '../../services/api';
import './dashboard.css';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import MaskedInput from 'react-maskedinput';
import { Form, InputGroup } from 'react-bootstrap';

const meses = {
  '01': 'Janeiro',
  '02': 'Fevereiro',
  '03': 'Março',
  '04': 'Abril',
  '05': 'Maio',
  '06': 'Junho',
  '07': 'Julho',
  '08': 'Agosto',
  '09': 'Setembro',
  '01': 'Janeiro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
};

function formatDate(date) {
  if (date) {
    let vars = date.split('T');
    let time = vars[1].slice(0, vars[1].length - 1);
    let dateEls = vars[0].split('-');
    return `Dia ${dateEls[2]} de ${meses[dateEls[1]]} de ${dateEls[0]
      } às ${time}.`;
  } else return '';
}

class ConsultasPendentes extends Component {
  state = {
    openModal: false,
    selectedConsulation: null,
    data: null,
    hora: null,
    descricao: '',
  };

  async sendAnswer(param) {
    await api
      .patch('/consultations/' + this.props.consultation.id + '/', {
        status: param,
      })
      .then(() => {
        console.log('XD');
        window.location.reload();
      });
  }

  toggle = (selectedConsulation = null) =>
    this.setState({
      openModal: !this.state.openModal,
      selectedConsulation: selectedConsulation,
    });

  render() {
    var doc = Object(this.props.doctor);
    if (this.props.consultation != null) {
      var cons = Object(this.props.consultation);
      var patient = Object(this.props.consultation.patient);
      var display_ = 'block';

      var optionDiv = (
        <div className='layout-bloco'>
          <div
            className='meu-bloco-botao'
            onClick={() => this.sendAnswer('Scheduled')}
          >
            Aceitar Consulta
          </div>
          <div
            className='cancel-bloco-botao'
            onClick={() => this.sendAnswer('Denied')}
          >
            Recusar Consulta
          </div>
        </div>
      );
      if (cons.status === 'Scheduled') {
        optionDiv = (
          <div className='layout-bloco'>
            <p className='text-call'>Realizar chamada com o paciente:</p>
            <Link
              to={`tokboxcall/${cons.id}`}
              params={{ consultation: cons.id }}
              className='btn btn-success'
              style={{ float: 'center', marginTop: '0%' }}
            >
              <i className='fa fa-video-camera' /> Iniciar Call{' '}
            </Link>
          </div>
        );
      } else if (cons.status === 'Denied') {
        optionDiv = (
          <div className='layout-bloco'>
            <div
              className='remarcar-btn'
              onClick={() => {
                this.toggle(cons);
              }}
            >
              Remarcar consulta
            </div>
            <div id='botao-cancel' style={{ marginTop: '1rem' }}>
              <p className='danger'>Consulta cancelada</p>
            </div>
          </div>
        );
      }

      //Verifica se paciente tem plano de saúde da Unimed
      var optionPlano = '';
      if (patient.is_unimed_client) {
        optionPlano = <b>Sim</b>;
      } else {
        optionPlano = <b>Não</b>;
      }
    } else {
      var cons = Object();
      var patient = Object();
      var display_ = 'none';
    }

    var td = (
      <tr>
        <td>
          <img
            src={patient.picture}
            alt='maior-profile'
            className='maior-profile'
          />{' '}
        </td>
        <td>
          <h3 className='myh3'>{patient.name}</h3>
          <p className='text-left'>{patient.phonenumber}</p>

          <p className='text-left custo-consulta destaque-verde'>
            Valor da Consulta: R${parseFloat(doc.price).toFixed(2)}
            <a href='/' id='pago'>
              <i className='fa fa-check-circle' /> Pago
            </a>
          </p>
          <p className='text-left custo-consulta'>Consulta agendada para:</p>
          <p className='text-left custo-consulta destaque-vermelho'>
            <i className='fa fa-calendar' /> {formatDate(cons.date)}
          </p>
        </td>
      </tr>
    );

    return (
      <div ref={this.props.refe} style={{zIndex: 1}}>
        {
          Object.keys(this.props.consultation).length ? (
            <Fragment>
              <Modal show={this.state.openModal} modalClosed={this.toggle}>
                {this.state.selectedConsulation &&
                  this.state.selectedConsulation.patient && (
                    <div>
                      <div className='row mt-3 mb-3'>
                        <div className='col'>
                          <div className='card'>
                            <div className='card-body flex-row'>
                              <img
                                src={this.state.selectedConsulation.patient.picture}
                                alt='icone-profile'
                                className='icone-profile'
                              />
                              <div>
                                <h6>{this.state.selectedConsulation.patient.name}</h6>
                                <p className='m-0'>
                                  {this.state.selectedConsulation.patient.phonenumber}
                                </p>
                                <p className='m-0'>
                                  Nas.:{' '}
                                  {this.state.selectedConsulation.patient.birthdate}
                                </p>
                                <p className='m-0'>
                                  Plano de saúde:{' '}
                                  <span>
                                    {this.state.selectedConsulation.patient
                                      .is_unimed_client
                                      ? 'Sim'
                                      : 'Não'}
                                  </span>
                                </p>
                                {/* <p className='m-0'>
                        Alérgico a alguma medicação: <span>Não</span>
                      </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                          <InputGroup>
                            <Form.Label>Data da consulta</Form.Label>
                            <MaskedInput
                              mask='11/11/1111'
                              type='text'
                              name='data'
                              className='input-modal'
                              autoComplete='off'
                              onChange={(e) =>
                                this.setState({ data: e.target.value })
                              }
                              value={this.state.data}
                            />
                          </InputGroup>
                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                          <InputGroup>
                            <Form.Label>Hora da Consulta</Form.Label>
                            <MaskedInput
                              className='input-modal'
                              mask='11:11'
                              type='text'
                              name='hora'
                              autoComplete='off'
                              onChange={(e) =>
                                this.setState({ hora: e.target.value })
                              }
                              value={this.state.hora}
                            />
                          </InputGroup>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          <InputGroup>
                            <Form.Label>Descreva o motivo da consulta</Form.Label>
                            <textarea
                              class='input-modal'
                              name='descricao'
                              onChange={(e) =>
                                this.setState({ descricao: e.target.value })
                              }
                              value={this.state.descricao}
                            ></textarea>
                          </InputGroup>
                        </div>
                      </div>
                      <div className='mt-3 d-flex justify-content-between'>
                        <div className=' '>
                          <button
                            className='btn btn-danger'
                            onClick={this.toggle}
                            type='button'
                          >
                            Cancelar remarcação
                          </button>
                        </div>
                        <div className=' '>
                          <button
                            className='btn btn-success'
                            style={{ backgroundColor: '#007469' }}
                            onClick={async () => {
                              console.log(this.state.selectedConsulation);
                              const date = this.state.data.split('/');
                              await api.patch(
                                '/consultations/' +
                                this.state.selectedConsulation.id +
                                '/',
                                {
                                  date: `${date[2]}-${date[1]}-${date[0]}T${this.state.hora}:00`,
                                  status: 'Pending',
                                  description: this.state.descricao,
                                }
                              );
                              this.props.getDoctorInfo().then(() => {
                                this.props.setcons(null);
                                this.toggle();
                              });
                            }}
                          >
                            Confirmar remarcação
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
              </Modal>
              <div
                className='card'
                style={{ display: display_ }}
              >
                <div className='row'>
                  <div id='outro-perfil'>
                    <table>
                      <tbody>{td}</tbody>
                    </table>
                  </div>
                  <div id='opcoes-bloco'>
                    <div className='bb'>
                      <div className='layout-bloco2'>
                        <p className='correct'>
                          Plano de Saude:<br></br>
                          {optionPlano}
                        </p>
                        <p className='correct'>
                          Para quem é o agendamento?<br></br>
                          <b>{patient.name}</b>
                        </p>
                        <p className='correct'>
                          Motivo da consulta?:<br></br>
                          <b>{cons.consultation_report}</b>
                        </p>
                      </div>
                    </div>
                    <div className='sub-bb'>{optionDiv}</div>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : null
        }
      </div>


    );
  }
}

export default ConsultasPendentes;
