import React, { useEffect, useState } from 'react';
import { getUserId } from '../../services/auth';
import { NavBar } from '../Navbar';
import api from '../../services/api';
import NewSideBar from '../NewSideBar';
import '../HomeDashboard/dashboard.css';
import ReportTable from '../../components/ReportTable';
import LoadingState from '../../components/LoadingState';
import PageHeader from '../../components/PageHeader';
import Calendar from '../../components/Calendar';
import { format, formatISO } from 'date-fns'

import {
  TableContainer,
  FilterDisplayContainer,
  Filter,
  InputTitle,
  TextInput,
  FilterDisplayRow,
  SearchButton,
  ButtonContainer,
  CalendarContainer,
  CloseButtonContainer,
  CloseButton,
} from './style';

function ReportsPage() {
  const [isLoading, setIsLoading] = useState(false);

  const [patients, setPatients] = useState([]);
  const [doctorName, setDoctorName] = useState("");
  const [doctorSpecialty, setDoctorSpecialty] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [openCalendar, setOpenCalendar] = useState(false);
  const [firstDateQuery, setFirstDateQuery] = useState(new Date(2020, 0, 1));
  const [lastDateQuery, setLastDateQuery] = useState(new Date());

  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phonenumber: '',
    is_doctor: false,
    council: {},
    medical_register: '',
    consultations: [],
    saveConsultations: [],
    doctor_agenda: [],
    category: null,
    specialty: null,
    accept_medical_agreement: true,
    medical_insurances: '',
    picture: '',
    error: '',
    price: 0,
    specialties: [],
    categories: [],
    medicalInsurances: [],
    councils: [],
    states: [],
    council_states: [],
    state: '',
    council: '',
    council_state: '',
    filename: '',
    picture: null,
    current_consultation: null,
    filterDay: 7,
  });


  const getDoctorInfo = async () => {
    setIsLoading(true);
    await api.get('/doctors/' + String(getUserId()) + '/').then((res) => {
      const data = res.data;
      setState({
        name: data.name,
        email: data.email,
        phonenumber: data.phonenumber,
        category: { id: data.category.id, name: data.category.name },
        specialty: data.specialty,
        accept_medical_agreement: data.accept_medical_agreement,
        medical_insurances: data.medical_insurances,
        picture: data.picture,
        council: data.council,
        medical_register: data.medical_register,
        consultations: data.doctor_consultations,
        saveConsultations: data.doctor_consultations,
        price: data.price,
        doctor_agenda: data.doctor_agenda,
      });

      setDoctorName(res.data.name);
      setDoctorSpecialty(res.data.specialty[0].name);
    });

    setIsLoading(false);
  };

  const getReports = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/consultations/report/?date_after=${formatISO(firstDateQuery).slice(0, 10).trim()}&date_before=${formatISO(lastDateQuery).slice(0, 10).trim()}&patient_name=${searchQuery}`);

      setPatients(res.data.results);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(firstDateQuery, lastDateQuery);
    getDoctorInfo();
    getReports();
  }, []);

  return (
    <div className='mydiv'>
      <NavBar state={state}></NavBar>
      <div className='newSideContext'>
        <NewSideBar />
        <div className='mycontent'>
          <PageHeader
            state={state}
            pageTitle='Relatórios'
            pageDescription='Baixe seu relatório em PDF'
            disabled={true}
          />

          <FilterDisplayContainer>
            <FilterDisplayRow>
              <Filter>
                <InputTitle>Período:</InputTitle>
                <button style={{ border: 'none', background: 'none', outline: 'none', padding: '0', cursor: 'pointer' }} onClick={() => setOpenCalendar(true)}>
                  <TextInput placeholder='Informe o período das consultas' value={`${firstDateQuery.getDate() + "/"
                    + format(firstDateQuery, 'LL') + "/" + firstDateQuery.getFullYear()} - ${lastDateQuery.getDate() + "/"
                    + format(lastDateQuery, 'LL') + "/" + lastDateQuery.getFullYear()}`} />
                </button>
              </Filter>
              {
                openCalendar ? (
                  <CalendarContainer>
                    <CloseButtonContainer>
                      <CloseButton style={{ outline: 'none' }} onClick={() => setOpenCalendar(false)}>x</CloseButton>
                    </CloseButtonContainer>

                    <Calendar 
                      firstDate={firstDateQuery}
                      setFirstDate={setFirstDateQuery}
                      lastDate={lastDateQuery}
                      setLastDate={setLastDateQuery}
                    />
                  </CalendarContainer>
                ) : null
              }

              <Filter>
                <InputTitle>Paciente:</InputTitle>
                <TextInput
                  onChange={e => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  placeholder='Digite o nome do paciente'
                />
              </Filter>
            </FilterDisplayRow>

            <ButtonContainer>
              <SearchButton
                style={{ outline: 'none' }}
                onClick={() => {
                  // setSearchQuery(nameQuery);
                  getReports();
                }}
              >
                Pesquisar
              </SearchButton>
            </ButtonContainer>
          </FilterDisplayContainer>

          {
            (isLoading || !doctorSpecialty || !doctorName) ? (
              <LoadingState />
            ) : (
              <TableContainer>
                <ReportTable
                  items={patients}
                  doctor={doctorName}
                  specialty={doctorSpecialty}
                  firstDate={firstDateQuery}
                  lastDate={lastDateQuery}
                />
              </TableContainer>
            )
          }

        </div>
      </div>
    </div>
  );
}

export default ReportsPage;
