import styled from 'styled-components';

export const MonthContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
`;

export const SingleContainer = styled.div`
    margin: 0 .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    position: relative;
`;

export const DatesContainer = styled.table`
  box-sizing: border-box;
  border: none;
  margin-top: 0rem;

  tr {
    border-collapse: collapse;
  }

  tr td {
    padding: .25rem .8rem;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
  }

  tr:first-child  {
    border-top: 2px solid #d7dadd;
    border-bottom: 2px solid #d7dadd;
  }
`;

export const DateCell = styled.td`
`;

export const ChangeMonthButton = styled.button`
    border: none;
    text-decoration: none;
    background: none;
    padding: 0rem .5rem;
`;

export const MonthRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;