import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from '@trendmicro/react-sidenav';

import imgteste from '../../assets/skins/layer_6_copy.png';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import styles from '../SignIn/css/login.module.css';
import './sidebar.css';

class SidebarComponent extends Component {
  render() {
    return (
      <nav
        className={
          styles.fadeIn +
          ' navbar navbar-light navbar-expand-lg ' +
          styles.second
        }
      >
        <SideNav
          onSelect={(selected) => {
            // Add your code here
          }}
          style={{ backgroundColor: '#007469', position: 'fixed' }}
          expanded
        >
          <SideNav.Nav>
            <NavItem
              expanded
              className='text-center'
              style={{ background: 'white', height: 68 }}
              disabled='true'
            >
              <img
                style={{ width: '80%', height: '100%', 'margin-top': '5%' }}
                src={imgteste}
              ></img>
            </NavItem>
            <NavItem>
              <NavIcon>
                <i
                  className='fa fa-fw fa-clock-o'
                  style={{ fontSize: '1.75em' }}
                />
              </NavIcon>
              <NavText>Consultas agendadas</NavText>
            </NavItem>
            <NavItem>
              <NavIcon>
                <i
                  className='fa fa-fw fa-user'
                  style={{ fontSize: '1.75em' }}
                />
              </NavIcon>
              <NavText>Minha Conta</NavText>
            </NavItem>
          </SideNav.Nav>
        </SideNav>
      </nav>
    );
  }
}

export const Sidebar = SidebarComponent;
