import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import TokboxComponent from "./tokbox.js"
import api from "../../services/api"
import NewSideBar from "../NewSideBar"
import { NavBar } from "../Navbar"

class TokboxPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      consultation: null,
      token: "",
      sessionId: "",
      doctor: {},
      patient: {},
    }
  }

  getTokbox = async () => {
    try {
      let tokbox_id = this.state.consultation.tokbox_session

      if (tokbox_id === null) {
        const response = await api.post(`/tokbox_sessions/`, {
          consultation: this.props.match.params.consultation,
        })
        tokbox_id = response.data.id
      }
      const response = await api.get(`/tokbox_sessions/${tokbox_id}/`)
      this.setState(
        {
          sessionId: response.data.session_id,
          token: response.data.token,
        },
        () => {
          console.log("STATE AFTER GET TOKBOX ->", this.state)
        }
      )
    } catch (error) {
      console.log("ERROR ON GET TOKBOX ->", error)
    }
  }

  getConsultation = async () => {
    try {
      const response = await api.get(
        `/consultations/${this.props.match.params.consultation}/`
      )
      this.setState({ consultation: response.data })

      await this.getDoctor()

      await this.getPatient()
    } catch (error) {
      console.log("ERROR ON GET CONSULTATION ->", error)
    } finally {
      await this.getTokbox()
    }
  }

  getDoctor = async () => {
    try {
      let doc = this.state.consultation.doctor
      const response = await api.get(`/doctors/${doc}/`)

      this.setState({ doctor: response.data })
    } catch (error) {
      console.log("ERROR ON GET DOCTOR ->", error)
    }
  }

  getPatient = async () => {
    try {
      let { id: patient } = this.state.consultation.patient
      const response = await api.get(`/patients/${patient}/`)
      this.setState({ patient: response.data })
    } catch (error) {
      console.log("ERROR ON GET PATIENT ->", error)
    }
  }

  async componentWillMount() {
    this.getConsultation()
  }

  render() {
    return (
      <div>
        <NavBar state={this.state.doctor} />
        <div className="newSideContext">
          <NewSideBar />
          {this.state.token ? (
            <TokboxComponent state={this.state} setState={this.setState} />
          ) : null}
        </div>


      </div>
    )
  }
}

export default withRouter(TokboxPanel)
