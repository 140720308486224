import React, { useState, useEffect } from 'react';
import { getUserId } from '../../services/auth';
import { NavBar } from '../Navbar';
import api from '../../services/api';
import NewSideBar from '../NewSideBar';
import PageHeader from '../../components/PageHeader';
import '../HomeDashboard/dashboard.css';
import HistoryConsultList from '../../components/HistoryConsultList';
import LoadingState from '../../components/LoadingState';


function HistoryPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [nameFilter, setNameFilter] = useState('');
  const [dateFilter, setDateFilter] = useState();

  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phonenumber: '',
    is_doctor: false,
    council: {},
    medical_register: '',
    consultations: [],
    saveConsultations: [],
    doctor_agenda: [],
    category: null,
    specialty: null,
    accept_medical_agreement: true,
    medical_insurances: '',
    picture: '',
    error: '',
    price: 0,
    specialties: [],
    categories: [],
    medicalInsurances: [],
    councils: [],
    states: [],
    council_states: [],
    state: '',
    council: '',
    council_state: '',
    filename: '',
    picture: null,
    current_consultation: null,
    filterDay: 7,
  });

  const getDoctorInfo = async () => {
    setIsLoading(true);
    await api.get('/doctors/' + String(getUserId()) + '/').then((res) => {
      const data = res.data;
      setState({
        name: data.name,
        email: data.email,
        phonenumber: data.phonenumber,
        category: { id: data.category.id, name: data.category.name },
        specialty: data.specialty,
        accept_medical_agreement: data.accept_medical_agreement,
        medical_insurances: data.medical_insurances,
        picture: data.picture,
        council: data.council,
        medical_register: data.medical_register,
        consultations: data.doctor_consultations,
        saveConsultations: data.doctor_consultations,
        price: data.price,
        doctor_agenda: data.doctor_agenda,
      });
    });
    console.log(state.consultations)
    setIsLoading(false);
  };

  useEffect(() => {
    getDoctorInfo();
  }, []);

  return (
    <div className='mydiv' >
      <NavBar state={state}></NavBar>
      <div className='newSideContext'>
        <NewSideBar getDoctorInfo={getDoctorInfo}></NewSideBar>
        <div className='mycontent'>
          <PageHeader
            state={state}
            pageTitle='Histórico de videochamadas'
            pageDescription='Histórico de videochamadas com pacientes organizados por mês'
            nameFilter={nameFilter}
            setNameFilter={setNameFilter}

            dateFilter={typeof dateFilter!=='undefined' ? dateFilter : null}
            setDateFilter={setDateFilter}
          />

          {
            !isLoading ? (
              <HistoryConsultList
                consultations={state.consultations}
                nameFilter={nameFilter}
                setNameFilter={setNameFilter}
                dateFilter={typeof dateFilter!=='undefined' ? dateFilter : null}
              />
            ) : (
              <LoadingState />
            )
          }
        </div>
      </div>
    </div >

  );
}

export default HistoryPage;