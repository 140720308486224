import styled from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
  overflow-y: scroll;
`;

export const FilterDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const FilterDisplayRow = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const FilterRow = styled(FilterDisplayRow)`
  display: flex;
  width: 99%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const InputTitle = styled.div`
  font-size: 19px;
  margin-bottom: .25rem;
  font-weight: bold;
  color: #007469;
`;

export const TextInput = styled.input`
  display: flex;
  /* width: 14rem; */
  width: 325px;
  border: 2px solid #007469;
  border-radius: 5px;
  padding: .6rem 1rem;

  :disabled{
    background: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

export const SearchButton = styled.button`
  background: #007469;
  color: white;
  border: none;
  border-radius: 5px;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  transition: 150ms;
`;

export const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 24rem;
    background: white;
    border: 2px solid #007469;
    border-radius: 5px;
    padding: .5rem;

    @media (max-width: 1200px) {
        margin-top: 6.5rem;
    }
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const CloseButton = styled.button`
    border: none;
    text-decoration: none;
    background: none;
    padding: 0rem .5rem;
    color: #007469;
    font-weight: 700;
    position: relative;
    outline: none;
`;

export const HomeFilterContainer = styled.div`
  display: flex;
  width: 14rem;
  border: 2px solid #007469;
  border-radius: 5px;
  padding: .6rem 1rem;
  margin-left: 1.5rem;
`;

export const HomeFilter = styled.input`
  width: 10.5rem;
  border: none;

  :disabled{
    background: none;
  }
`;