import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import styled from 'styled-components';

export const SelectComponent = styled(ReactSelect)`
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const SelectOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;