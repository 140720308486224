
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import api from "../../services/api";
//import "./css/css_002.css"
//import "./css/css.css"
import '../../assets/fonts/lato_font.css'
import '../../assets/fonts/montserrat_font.css'
import styles from './css/lost.module.css'
import loginstyles from "../SignIn/css/login.module.css"
import { Container, NotifyError, FormContent, ContainerInfo } from "./styles";
import { FormFooter, BOTAO, InputComponent, SPAN, LABEL, H1 } from "../SignIn/styles";
import { login } from "../../services/auth";



class ForgotPassword extends Component {
  state = {
    email: ""
  };

  handleLostPassword = async e => {
    e.preventDefault();
    const { email } = this.state;
    if (!email) {
      this.setState({ error: "Preencha e-mail para continuar!" });
    } else {
      try {
        const response = await api.post("/password_reset/", { "email": email});      
        this.props.history.push("/signin");
      } catch (err) {
        console.log(err)
        this.setState({
          error:
            err.response.data.email
        });
      }
    }
  };  

  render() {
    return (
    <Container style={{"background-color": "#007469"}}>
        <div className={loginstyles.wrapper + " " + loginstyles.fadeInDown}>
            <FormContent>
                <form onSubmit={this.handleLostPassword}>
                    <H1 className={styles.subtitle}>Esqueci minha senha</H1>
                    <p id={styles.person}>Insira seu Email para o procedimento de redefinição de senha</p>
                    <NotifyError>
                    {this.state.error && <p><font color="red">{this.state.error}</font></p>}
                    </NotifyError>
                    <LABEL>E-mail</LABEL>
                    <InputComponent type="email" id="login" className={loginstyles.fadeIn + " " + loginstyles.second} name="login" placeholder="email@exemplo.com" onChange={e => this.setState({ email: e.target.value })} required />
                    <ContainerInfo>
                      <BOTAO type="submit" className={loginstyles.fadeIn + " " + loginstyles.fourth} value="Enviar"/>
                    </ContainerInfo>
                </form>
                
                <FormFooter>
                    <Link className={loginstyles.link + " " + loginstyles.underlineHover} to="signin" id={loginstyles.bbox}><div className='fa fa-long-arrow-left'></div> Voltar</Link>
                </FormFooter>
                
            </FormContent>
        </div>
    </Container>
    );
  }
}

export default withRouter(ForgotPassword);