import styled from 'styled-components';

export const FilterRow = styled.div`
  display: flex;
  width: 97.5%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const HomeFilterContainer = styled.div`
  display: flex;
  width: 14rem;
  border: 2px solid #007469;
  border-radius: 5px;
  padding: .6rem 1rem;
  margin-left: 1.5rem;
`;

export const HomeFilter = styled.input`
  width: 10.5rem;
  border: none;

  :disabled{
    background: none;
  }
`;

export const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 14rem;
    position: absolute;
    margin-top: 3.5rem;
    background: white;
    border: 2px solid #007469;
    border-radius: 5px;
    padding: .5rem;
    z-index: 10;
`;