import React from 'react';
import {
    CardContainer,
    InfoContainer,
    InfoRow
} from './style';

function Card({
    name,
    date,
    cvv,
    number
}) {
    // const name = "JohnDoe";
    // const date = "00/00";
    // const cvv = "000";
    // const number = "0000-0000-0000-0000";


    return (
        <CardContainer>
            <InfoContainer>
                <InfoRow>{number}</InfoRow>
                <InfoRow style={{ display: "flex", justifyContent: "space-between"}}>
                    <div>{date}</div>
                    <div>{cvv}</div>
                </InfoRow>
                <InfoRow style={{ textOverflow: "ellipsis", overflow: "hidden"}}>{name}</InfoRow>
            </InfoContainer>
        </CardContainer>

    );
}

export default Card;