import styled from "styled-components";
import { InputComponent } from "../SignIn/styles";
import copyimg from "../../assets/images/copy.png";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const FormContent = styled.div`
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #fff;
  width: 90%;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  max-width: 50%;
  padding: 2%;
  padding-left: 1%;
  text-align: left;
`;

export const H1 = styled.h1`
  font-size: 26px;
  text-align: center;
`;
export const Person = styled.p`
  font-size: 15px;
  font-family: Lato;
  color: #aaa;
  text-align: center;
  margin-bottom: 20px;
`;

export const InputComponent2 = styled.input`
  & {
    background-color: #fff;
    border: none;
    color: #0d0d0d;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 5px;
    width: 100% !important;
    border: 1px solid #ccc;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    text-align: left;
  }

  :focus {
    background-color: #fff;
    border-bottom: 2px solid rgba(21, 129, 117, 255);
  }
  ::placeholder {
    color: #cccccc;
  }
`;

export const SELECT = styled.select`
  & {
    background-color: #fff;
    border: none;
    color: #0d0d0d;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 5px;
    width: 85%;
    border: 1px solid #ccc;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    text-align: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background: #fff url(${copyimg}) 95.5% 50% no-repeat;
    background-size: 13px;
  }

  :focus {
    background-color: #fff;
    border-bottom: 2px solid rgba(21, 129, 117, 255);
  }
  ::placeholder {
    color: #cccccc;
  }
`;

export const CHECKBOX = styled.input.attrs({ type: "checkbox" })`
  display: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  display: inline-block;
  cursor: pointer;
`;
