import React, { useState } from 'react';
import '../../pages/HomeDashboard/dashboard.css';
import { subDays, formatISO } from 'date-fns';
import {
    Table,
    TableContainer
} from './style';

import profile_male from '../../assets/images/icons/profile_male.png';
import profile_female from '../../assets/images/icons/profile_female.png';

const meses = {
    '01': 'Janeiro',
    '02': 'Fevereiro',
    '03': 'Março',
    '04': 'Abril',
    '05': 'Maio',
    '06': 'Junho',
    '07': 'Julho',
    '08': 'Agosto',
    '09': 'Setembro',
    '01': 'Janeiro',
    '10': 'Outubro',
    '11': 'Novembro',
    '12': 'Dezembro',
};

function formatDate(date) {
    if (date) {
        let vars = date.split('T');
        let time = vars[1].slice(0, vars[1].length - 1);
        let dateEls = vars[0].split('-');
        return `Dia ${dateEls[2]} de ${meses[dateEls[1]]} de ${dateEls[0]
            } às ${time.slice(0, 8)}.`;
    } else return '';
}

function HistoryConsultList({ consultations, nameFilter, dateFilter }) {
    const [patients, setPatients] = useState(consultations);

    //   setCurrentConsultation(val) {
    //     this.props.setcons(val);
    //   }

    const filterConsultations = (dateConsultation) => {
        const dateFilter = subDays(
            new Date().setHours(0, 0, 0, 0),
            this.props.filterDay
        );
        console.log('foi', dateFilter.getTime())
        const date = dateConsultation.replace('Z', '');
        const dateConsult = new Date(date);

        return dateConsult.getTime() >= dateFilter.getTime();
    }

    function ShowPatients() {
        // let consultations = this.props.state.consultations;

        return (
            patients
                .filter((consultation) => {
                    if((!nameFilter || consultation.patient.name.toLowerCase().includes(nameFilter.toLowerCase().trim()))
                    && (!dateFilter || consultation.date.slice(0, 10).includes(formatISO(dateFilter).slice(0, 10)))) return consultation;
                })
                .map((consultation) => {
                    // var cons = Object(consultation.patient);
                    return (
                        <tr key={consultation.id}>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <img
                                        src={consultation.patient.picture ? consultation.patient.picture :
                                            (consultation.patient.genre === 'M' ? profile_male : profile_female)}
                                        className='icone-profile'
                                    />
                                    <div>{consultation.patient.name}</div>
                                </div>
                            </td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'left', color: '#007469', lineHeight: '1rem' }}>
                                    <i style={{ paddingRight: '.5rem' }} className='fa fa-calendar space-p' />
                                    {formatDate(consultation.date)}
                                </div>
                            </td>
                        </tr>
                    );
                })
        );
    }


    return (
        patients.filter((consultation) => {
            if((!nameFilter || consultation.patient.name.toLowerCase().includes(nameFilter.toLowerCase().trim()))
            && (!dateFilter || consultation.date.slice(0, 10).includes(formatISO(dateFilter).slice(0, 10)))) return consultation;
        }).length ? (
            <TableContainer>
                <Table>
                    <thead>
                        <tr>
                            <th>Nome do Paciente</th>
                            <th>Data da Videochamada</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ShowPatients />
                    </tbody>
                </Table>
            </TableContainer>
        ) : (
            <table>
                <tbody>
                    <tr>
                        <td colSpan='4' className='text-center'>
                            <h1 className='myh1'>Sem pacientes no momento.</h1>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    );
}

export default HistoryConsultList;