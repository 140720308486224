import React, { Component } from "react";
import { Modal, Button, Form, Tab, Tabs } from "react-bootstrap";
import FinishCallIcon from "../../assets/images/phone.png";
import VolumeIcon from "../../assets/images/volume-icon.png";
import MuteIcon from "../../assets/images/mute-icon.png";
import VideoOff from "../../assets/images/video-off.png";
import VideoOn from "../../assets/images/video-on.png";
import { withRouter } from "react-router-dom";

import {
  OTSession,
  OTPublisher,
  OTStreams,
  OTSubscriber,
  preloadScript,
} from "opentok-react";
import { format, parseISO } from "date-fns";

import ConnectionStatus from "./connectionStatus";
import Publisher from "./Publisher";
import Subscriber from "./Subscriber";
import "./tokbox.css";
import api from "../../services/api";

import {
  FormAnamnese,
  FormDocs,
  FormAlert,
  FormCheckList,
  FormPrescricao,
  FormAtestado,
  FormHistorico,
} from "./Forms";

const CHROME_EXTENSION_ID = "baz";

class TokboxComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      connected: false,
      ret: "",
      areavalue: "",
      audio: true,
      video: true,
    };
    console.log(props.state);
    this.sessionEvents = {
      sessionConnected: () => {
        console.log("SESSION CONNECTED");
        this.setState({ connected: true, error: "" });
      },
      sessionDisconnected: () => {
        console.log("SESSION DISCONNECTED");
        this.setState({ connected: false });
      },
    };

    this.textLog = React.createRef();
  }

  onError = (err) => {
    this.setState({ error: `Failed to connect: ${err.message}` });
  };

  callProntuario = () => {
    window.open(
      "https://pep.arquimedsaude.com.br/login",
      "_blank",
      "toolbar=yes, scrollbars=yes, resizable=yes, top=20, left=900, width=500, height=700"
    );
  };

  handleKeyPress = async (event) => {
    try {
      if (event.key == "Enter") {
        await api.patch(`/consultations/${this.props.state.consultation.id}/`, {
          consultation_report: this.state.areavalue,
        });
        console.log("salvou");
      }
    } catch (error) {
      console.log(error);
    }
  };

  async getDiluents() {
    try {
      const { data } = await api.get(`/diluents/`);
      if (data[0]) {
        this.setState({ diluents: [...data] });
      } else {
        this.setState({ diluents: [data] });
      }
    } catch (error) {
      console.log(error);
    }
  }

  onError = (err) => {
    this.setState({ error: `Failed to connect: ${err.message}` });
  };

  componentDidMount() {
    this.setState({
      areavalue: this.props.state.consultation.consultation_report,
    });
    // this.setState({ medications: this.props.state.registered_medications.item })
    // this.callProntuario();
  }

  // async componentWillMount() {
  //   await this.getPatient();
  //   await this.getDocuments();
  //   await this.getMedications();
  //   await this.getDiluents();
  // }

  render() {
    const {
      name,
      cpf,
      genre,
      last_consultation,
      birthdate,
    } = this.props.state.consultation.patient;

    console.log(this.props.state.consultation);

    return (
      <div className="tokboxdiv">
        <div className="tokboxCall">
          <OTSession
            className="card"
            apiKey="46623132"
            sessionId={
              this.props.state.sessionId === ""
                ? "0"
                : this.props.state.sessionId
            }
            token={this.props.state.token === "" ? "0" : this.props.state.token}
            eventHandlers={this.sessionEvents}
            onError={this.onError}
          >
            {this.state.error ? <div>{this.state.error}</div> : null}
            <div className="patient-div">
              <OTStreams>
                <Subscriber />
              </OTStreams>
            </div>
            <div className="doctor-div">
              <Publisher video={this.state.video} audio={this.state.audio} />
            </div>
          </OTSession>
          <div class="container-button">
            {this.state.audio ? (
              <button
                class="button-call"
                type="button"
                onClick={() => this.setState({ audio: false })}
              >
                <img
                  alt="Finalizar chamada"
                  src={VolumeIcon}
                  width="95%"
                  height="95%"
                />
              </button>
            ) : (
              <button
                class="button-call"
                type="button"
                onClick={() => this.setState({ audio: true })}
              >
                <img
                  alt="Finalizar chamada"
                  src={MuteIcon}
                  width="95%"
                  height="95%"
                />
              </button>
            )}

            <button
              class="button-call button-finish"
              type="button"
              onClick={() => this.props.history.push("/app")}
            >
              <img
                alt="Finalizar chamada"
                src={FinishCallIcon}
                width="115%"
                height="100%"
              />
            </button>

            {this.state.video ? (
              <button
                class="button-call"
                type="button"
                onClick={() => this.setState({ video: false })}
              >
                <img
                  alt="Finalizar chamada"
                  src={VideoOn}
                  width="95%"
                  height="95%"
                />
              </button>
            ) : (
              <button
                class="button-call"
                type="button"
                onClick={() => this.setState({ video: true })}
              >
                <img
                  alt="Finalizar chamada"
                  src={VideoOff}
                  width="90%"
                  height="90%"
                />
              </button>
            )}
          </div>
        </div>
        <div className="screen">
          {/* tela aqui */}
          <Button className="madal-btn mt-3" onClick={() => this.callProntuario}>
            Cadastrar prontuário
          </Button>
          {/* <iframe
              style={{
                height: "100%",
                width: "35%",
              }}
              src="https://pep.arquimedsaude.com.br/"
              title="Arquimed"
            ></iframe> */}
          {/* {this.props.state.consultation.created_by_doctor ? (
            <iframe
              style={{
                height: "100%",
                width: "35%",
              }}
              src="https://arquimed.unilabs.com.br/"
              title="Arquimed"
            ></iframe>
          ) : ( */}
            <div className="sideForm">
              <div className="cabecalho">
                <div>
                  <h5>Nome</h5>
                  <p>{name}</p>
                </div>

                <div>
                  <h5>Ult. Consulta</h5>
                  <p>
                    {last_consultation &&
                      format(parseISO(last_consultation), "dd/M/Y")}
                  </p>
                </div>
                <div>
                  <h5>Diag.</h5>
                  <p>CID: {this.props.state.consultation.cid}</p>
                </div>
              </div>
              <Tabs defaultActiveKey="anamnese" id="uncontrolled-tab-example">
                <Tab eventKey="anamnese" title="Anamnese">
                  <FormAnamnese
                    consultation={this.props.state.consultation}
                    setState={this.props.setState}
                  />
                </Tab>
                <Tab eventKey="Alertas" title="Alertas">
                  <FormAlert
                    consultation={this.props.state.consultation}
                    state={this.props.state}
                    setConsultation={this.props.setConsultation}
                  />
                </Tab>
                <Tab eventKey="Checklist" title="Checklist">
                  <FormCheckList
                    consultation={this.props.state.consultation}
                    state={this.props.state}
                    setConsultation={this.props.setConsultation}
                  />
                </Tab>
                <Tab eventKey="Prescricoes" title="Prescrições">
                  <FormPrescricao
                    consultation={this.props.state.consultation}
                  />
                </Tab>
                <Tab eventKey="GestaoDocExames" title="Gestão Doc. e Exames">
                  <FormDocs
                    consultation={this.props.state.consultation}
                    documents={this.state.documents}
                  />
                </Tab>
                <Tab
                  eventKey="AtestadosDeclarações"
                  title="Atestados e Declarações"
                >
                  <FormAtestado consultation={this.props.state.consultation} />
                </Tab>
                <Tab
                  eventKey="HistoricoAtendimentos"
                  title="Historico de Atendimentos"
                >
                  <FormHistorico consultation={this.props.state.consultation} />
                </Tab>
              </Tabs>
            </div>
          {/* )} */}
        </div>
      </div>
    );
  }
}

export default withRouter(TokboxComponent);
